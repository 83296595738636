/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/logo_1.png";
import SignUpIcon from "../assets/Navbar Icons/login.svg";
import SignUpModal from "./SignUpModal";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import RegisterModal from "./RegisterModal";
import { NavbarData } from "./StaticData";
import { platformUrl } from "../../config";

const NavbarMain = () => {
  return (
    <>
      <div className="container-fluid sticky">
        <div className="col-12 mx-auto ">
          <nav className="navbar navbar-expand-xl ">
            <div className="container-fluid">
              <NavLink className="navbar-brand" to="/">
                <img src={Logo} alt="logo" height="60px" />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <RiBarChartHorizontalLine />
                </span>
              </button>
              <div
                className="collapse navbar-collapse justify-content-between align-items-center"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mr-auto align-items-center">
                  {NavbarData.Discountdata.map((key, index) => {
                    return (
                      <li className="nav-item me-3" key={index}>
                        <NavLink
                          className="nav-link active nav-menu"
                          aria-current="page"
                          to={key.Url}
                        >
                          {key.Name}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
                <div className="d-flex navbar-nav mr-auto align-items-center gap-3">
                  {NavbarData.ContactData.map((key, index) => {
                    return (
                      <li className="nav-item">
                        <NavLink className="nav-link nav-menu" to={key.Url}>
                          {key.Name}
                        </NavLink>
                      </li>
                    );
                  })}
                  <li className="nav-item ">
                    <div
                      className="modal fade"
                      id="exampleModalToggle"
                      aria-hidden="true"
                      aria-labelledby="exampleModalToggleLabel"
                      tabIndex="-1"
                    >
                      <SignUpModal />
                    </div>

                    <button
                      className="btn btn-outline-info"
                      // data-bs-toggle="modal"
                      onClick={()=>{window.location = platformUrl + "/login"}}
                      href="#exampleModalToggle"
                      role="button" hrefLang="en-us" rel="alternate"
                    >
                      Login
                    </button>
                  </li>
                  <li className="nav-item ">
                    <div
                      className="modal fade"
                      id="exampleModalToggle2"
                      aria-hidden="true"
                      aria-labelledby="exampleModalToggleLabel2"
                      tabIndex="-1"
                    >
                      <RegisterModal />
                    </div>

                    <button
                      className="btn btn-primary "
                      // data-bs-toggle="modal"                      
                      onClick={()=>{window.location = platformUrl + "/registration"}}
                      href="#exampleModalToggle2"
                      role="button" hrefLang="en-us" rel="alternate"
                    >
                      <img src={SignUpIcon} alt="signup icon" className="me-1" />
                      Sign Up
                    </button>
                  </li>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
export default NavbarMain;
