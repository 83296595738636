import { MdDashboardCustomize } from "react-icons/md";
import { BiCloudUpload } from "react-icons/bi";

export const SideNavbarLink = [
  {
    Name: "Dashboard",
    Icon: <MdDashboardCustomize className="fs-4 me-2" />,
    Url: "dashboard",
  },
  {
    Name: "Blog Details",
    Icon: <BiCloudUpload className="fs-4 me-2" />,
    Url: "blog-data",
  },
];
