import React, { useState, useEffect } from "react";

const UserRegister = () => {

    return (
        <>
        <h1>dfdsfdsf</h1>
        </>
    )

}

export default UserRegister;