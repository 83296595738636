/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const ExportInvoice = ({ KeyPoints, children }) => {
  return (
    <div className="container my-5">
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-10 col-sm-10 text-center">
          <iframe
            width="420"
            height="345"
            src="https://www.youtube.com/embed/Oe7DIlTnGsM"
            className="rounded-3 "
          ></iframe>
        </div>
        <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12 m-auto">
          {children}
          <ul className="MarkerPoint">
            {KeyPoints.map((key, index) => {
              return (
                <li key={index} className="mt-2">
                  {key}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ExportInvoice;
