import RegisterImg from "../assets/Export Invoice Discounting/register-img.jpeg";
import ShippingImg from "../assets/Export Invoice Discounting/buyercheck.jpeg";
import UploadInvoice from "../assets/Export Invoice Discounting/upload-invoice.jpg";
import Repayment from "../assets/Export Invoice Discounting/Repayment.jpg";

export const KeyPoints = [
  "Basic credit information, KYC, details of counterparties, LC issuing banks and exporter business background and any additional information that might be specifically required from Exporter.",
  "One-time approvals are generally needed from LC discounting Bank. For each transaction, the financing bank's details (and clauses if any) will need to be incorporated into the LC(letter of credit) . It is strongly advised to pre-discuss each transaction to avoid issues later.",
  "Once LC(letter of credit)  is received by discounting bank , Exporter will ship the goods and submit the trade & other related documents required under LC.",
  "Discounting bank / Confirming bank will discount the LC and credit the account of Exporter after deducting interest and other charges for usance period of the LC.",
];

export const ProcessData = {
  ProcessNavData: [
    {
      ID: "Home",
      Heading: "Sign Up",
      Description:
        "Sign Up to Trade reboot Finance Platform by just entering IEC & GST No. Fill up the sign up form  within 30 secs you will be able to log in ",
      ID2: "home-pill",
    },
    {
      ID: "Home2",
      Heading: "  Supplier Uploads LC",
      Description:
        "Supplier uploads basic information of trade and uploads LC on tradereboot platform.",
      ID2: "home2-pill",
    },
    {
      ID: "Home3",
      Heading: "Selection & Acceptance",
      Description:
        "Once LC is been uploaded  exporter will get quote from banks who are available on our platform.",
      ID2: "home3-pill",
    },
    {
      ID: "Home4",
      Heading: "Discounting",
      Description:
        "After KYC Check and Documents check  and getting acceptance from Issuing Bank and Buyer. Discounting Bank will discount the LC and credit the amount to exporter account. ",
      ID2: "home4-pill",
    },
  ],
  ProcessImgData: [
    {
      ID: "Home",
      ID2: "home-pill",
      Image: RegisterImg,
    },
    {
      ID: "Home2",
      ID2: "home2-pill",
      Image: ShippingImg,
    },
    {
      ID: "Home3",
      ID2: "home3-pill",
      Image: UploadInvoice,
    },
    {
      ID: "Home4",
      ID2: "home4-pill",
      Image: Repayment,
    },
  ],
};
