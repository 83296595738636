import React from "react";
import Logo from "../../assets/logo_1.png";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="container px-5 pb-5">
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-xl-10 col-lg-9 col-md-5 col-sm-5 col-4">
            <img
              src={Logo}
              alt="logo"
              className="img-fluid "
              height="150px"
              width="150px"
            />
            <div className="col-xl-3 col-lg-3 col-md-7 col-sm-8 col-8 cursor mt-4">
              <div className="d-flex gap-2 ms-2">
                <a
                  href="https://www.facebook.com/TradeReboot"
                  target="_blank"
                  rel="noreferrer"
                  hrefLang="en-us"
                >
                  <FaFacebookSquare className="fs-4 CustomColor IconAnimation" />
                </a>
                <a
                  href="https://www.instagram.com/tradereboot_/?hl=en"
                  target="_blank"
                  rel="noreferrer"
                  hrefLang="en-us"
                >
                  <FaInstagram className="fs-4 CustomColor IconAnimation" />
                </a>
                <a
                  href="https://www.linkedin.com/company/trade-reboot/"
                  target="_blank"
                  rel="noreferrer"
                  hrefLang="en-us"
                >
                  <FaLinkedin className="fs-4 CustomColor IconAnimation" />
                </a>
              </div>
              <small className="text-secondary ms-2 text-nowrap">
                All Right Reserved.
              </small>
            </div>
          </div>
          <div className="col-2 text-end">
            <ul className="list-unstyled">
              <li className="mt-4">
                <NavLink
                  className="nav-link nav-menu fs-6 text-dark text-nowrap"
                  to="/about-us"
                >
                  About Us
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  className="nav-link nav-menu fs-6 text-dark text-nowrap"
                  to="/contact-us"
                >
                  Contact Us
                </NavLink>
              </li>
              <li className="">
                <NavLink
                  className="nav-link nav-menu fs-6 text-dark text-nowrap"
                  to="/on-boarding"
                >
                  Onboarding
                </NavLink>
              </li>
              <li className="">
                <a
                  className="nav-link nav-menu fs-6 text-dark text-nowrap"
                  href="./Privacy_&_Cookie_Policy.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
