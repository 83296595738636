import { Routes, Route } from "react-router-dom";
import "./Custom.css";
import HomeComponent from "./components/Home Component/HomeComponent";
import ContactUs from "./components/Contact Component/ContactUs";
import Aboutus from "./components/About Us Component/Aboutus";
import FreightFinance from "./components/FreightFinance Component/FreightFinance";
import ExportInvoiceDiscounting from "./components/ExportInvoice Discounting/ExportInvoiceDiscounting";
import SupplyChain from "./components/Supply chain/SupplyChain";
import ExportLcDiscounting from "./components/Export LC Discounting components/ExportLcDiscounting";
import CaseStudies from "./components/case studies/CaseStudies";
import Onboarding from "./components/Onboarding Component/Onboarding";
import Blog from "./components/Blogs/Blog";
import BlogDescription from "./components/Blogs/BlogDescription";
import Dashboard from "./components/Admin pannel/Dashboard";
import BlogUpload from "./components/Admin pannel/BlogUpload";
import AdminLayout from "./components/Admin pannel/components/AdminLayout";
import UserLayout from "./components/UserLayout";
import UserLogin from "./components/Login/Sign-up/UserLogin";
import PageError from "./components/common-components/PageError";
import UserRegister from "./components/Login/UserRegister";

function App() {
  return (
    <Routes>
      <Route path="/" element={<UserLayout />}>
        <Route index element={<HomeComponent />} />
        <Route path="/case-studies/:domain" element={<CaseStudies />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<Aboutus />} />
        <Route path="/freight-finance" element={<FreightFinance />} />
        <Route path="/export-invoice" element={<ExportInvoiceDiscounting />} />
        <Route path="/supply-chain" element={<SupplyChain />} />
        <Route
          path="/export-lc-discounting"
          element={<ExportLcDiscounting />}
        />
        <Route path="/on-boarding" element={<Onboarding />} />
      </Route>
      {/* <Route path="/user-login" element={<UserLogin />} /> */}
      <Route path="/user-register" element={<UserRegister />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:domain" element={<BlogDescription />} />
      <Route path="/:404" element={<PageError />} />
    </Routes>
  );
}

export default App;
