import React from "react";
import { AiFillHome } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { platformUrl } from "../../../config";
import Logo from "../../assets/logo_1.png";

const Navbar = () => {
  return (
    <div className="container pt-2">
      <div className="row align-items-center">
        <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-2">
          <Link to="/" className="text-dark">
            <AiFillHome className="fs-5 CustomColor" />
          </Link>
        </div>
        <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-10">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-4 col-sm-4 col-md-4">
              <NavLink to="/blog">
                <img src={Logo} alt="logo" className="" height="110px" />
              </NavLink>
            </div>
            <div className="col-xl-6 col-lg-8 col-sm-12 col-md-12 col-12 text-end">
              <ul className="nav justify-content-end ">
                <li className="nav-item ">
                  <NavLink className="nav-link nav-menu fs-6" to="/about-us">
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link nav-menu fs-6" to="/contact-us">
                    Contact Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <a className="nav-link nav-menu fs-6" href={platformUrl + "/login"}>
                    Login
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navbar;
