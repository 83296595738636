import React from "react";
import comingsoon from "../assets/freight-finance/coming-soon.png";
import ShortCut from "../common-components/ShortCut";
import { Helmet } from "react-helmet";
import { Fade } from "react-reveal";

const FreightFinance = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <title>Apply for Freight / Cargo Finance Solutions | Trade Reboot Fin</title>
        <meta
          name="description"
          content="Freight finance or factoring helps to receive payment within two days or sooner by buying  invoices from shippers. it improves cash flow and trade time."
        />
      </Helmet>
      <div className="h-auto m-auto container text-center my-5 py-5">
        <h1 className="">
          <Fade top>
            <img src={comingsoon} alt="coming soon" className="img-fluid" />
          </Fade>
          
        </h1>
      </div>
      <ShortCut />
    </>
  );
};
export default FreightFinance;
