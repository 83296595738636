export const EMarketplaceData = [
  {
    heading: "Zero Documents",
    Image: require("../assets/onboarding/no-documents.jpeg"),
    Paragraph:
      "Easiest way for enterprises to onboard No need of hard documents ",
    classnames: "card-body py-0",
  },
  {
    heading: "Fast Process",
    Image: require("../assets/onboarding/security.jpeg"),
    Paragraph: "With Quick Response time complete KYC process in Seconds",
    classnames: "card-body py-0",
  },
  {
    heading: "Secure & Reliable",
    Image: require("../assets/onboarding/fast-process.jpeg"),
    Paragraph: "Authenticate Government data sources In Real Time",
    classnames: "card-body py-0",
  },
  {
    heading: "Real Time",
    Image: require("../assets/onboarding/real-time.jpeg"),
    Paragraph:
      "Provides smooth onboarding experience by verifying in Real Time",
    classnames: "card-body py-0",
  },
];

export const ProcessData = [
  "Sign-Up/Login",
  "Add Buyer Details",
  "Upload Invoice",
  "Process & Edit Profile",
];

export const ProcessCardsData = [
  {
    Number: "01",
    Heading: "Sign Up & Login",
    Description: "Submit Basic Business Details & Login to Platform",
  },
  {
    Number: "02",
    Heading: "Buyer Approval",
    Description: "Submit Buyer Details & Request Credit  Facility to Lenders",
  },
  {
    Number: "03",
    Heading: "Apply for Finance",
    Description:
      " After getting Buyer Approved from  Lenders Upload Trade Documents",
  },
  {
    Number: "04",
    Heading: "Due Date",
    Description:
      "Buyer Pays on Due to Lender now you can again apply for finance",
  },
  {
    Number: "05",
    Heading: "Fund Disbursement",
    Description: "Lenders Will disburse funds directly to your account",
  },
  {
    Number: "06",
    Heading: "Underwriting",
    Description:
      "Lenders will complete Due Diligence and Complete Under Writing Process and provide Term Sheet",
  },
];
