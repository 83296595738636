import React from "react";

const InputField = ({
  Label,
  InputType,
  Placeholder,
  Id,
  Name,
  Value,
  OnChange,
  Astrix,
  ValidError,
}) => {
  return (
    <>
      <div className="form-floating mb-3">
        <input
          type={InputType}
          className={`form-control  ${
            ValidError === "Field Missing" ? "validation-input" : "FormControl"
          }`}
          id={Id}
          placeholder={Placeholder}
          name={Name}
          value={Value}
          onChange={OnChange}
        />
        <label htmlFor="floatingInput">
          {Label} {Astrix}
        </label>
        {/* {ValidError === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''} */}
        {ValidError ? (
          <span className="text-danger fontValid">{ValidError}</span>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default InputField;
