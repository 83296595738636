import React, { Suspense, useEffect, useState } from "react";
import { tradeFinBackendUrl } from "../../config";
import Loader from "../common-components/Loader";
import ShortCut from "../common-components/ShortCut";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
// import {
//   BlogDataDescription3,
//   BlogDataDescription2,
//   BlogDataDescription,
//   BlogDataDescription4,
//   BlogHeading,
//   Seemoredata,
//   BlogHeading2,
//   BlogHeading3,
//   BlogHeading4,
// } from "./StaticData";
import axios from "axios";

const BlogDesc = React.lazy(() => import("./components/BlogDesc"));
// const SeeMore = React.lazy(() => import("./components/SeeMore"));
const BlogBanner = React.lazy(() => import("./components/BlogBanner"));

const BlogDescription = () => {
  const [blogData, setBlogData] = useState({})

  useEffect(() => {
    let blogUrl = window.location.href.split("/blog/")[1]

    axios.post(tradeFinBackendUrl + "/blogs/fetch-blogs", {blogUrl}).then((result) => {
      if (result.data && result.data.data) {
        setBlogData(result.data.data.items[0])
      }
    });

  }, [])

  // const descFunc = () => {
  //   const desclocation = window.location.pathname;
  //   switch (desclocation) {
  //     case "/blog/post-1":
  //       setDescription(BlogDataDescription);
  //       setMainheading(BlogHeading);
  //       break;
  //     case "/blog/post-2":
  //       setDescription(BlogDataDescription2);
  //       setMainheading(BlogHeading2);
  //       break;
  //     case "/blog/post-3":
  //       setDescription(BlogDataDescription3);
  //       setMainheading(BlogHeading3);
  //       break;
  //     case "/blog/post-4":
  //       setDescription(BlogDataDescription4);
  //       setMainheading(BlogHeading4);
  //       break;
  //     default:
  //       return BlogDataDescription;
  //   }
  // };
  // useEffect(() => {
  //   descFunc();
  // }, []);
  return (
    <div className="bg-light">
      <Navbar />
      <Suspense fallback={<Loader />}><BlogBanner data={blogData} /></Suspense>
      
      <Suspense fallback={<Loader />}>
        <BlogDesc data={blogData} Status="login" />
      </Suspense>
      <Footer />
      <ShortCut />
    </div>
  );
};
export default BlogDescription;
