import React from "react";
import Carousel from "./Carousel";
import Image1 from "../../assets/our team/Dhiraj.jpg";
import Image2 from "../../assets/our team/Shubham.png";
import Image3 from "../../assets/our team/Vikram.jpg";
import Image4 from "../../assets/our team/Garima.jpg";
import Image5 from "../../assets/our team/Arpit.jpg";

const Team = () => {
  return (
    <div className="container OurTeam mt-5">
      <div className="text-center ">
        <h2 className="">
          Meet <span style={{ color: "rgb(101, 228, 253)" }}>Our Team </span>
        </h2>
      </div>

      <div className="container">
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active ">
              <Carousel
                Image={Image1}
                Name="CA Dhiraj Khandelwal "
                Role="Mentor & Co-founder"
                Description="He is a Chartered Accountant having  practice in accounting and auditing for 20+ years. He also has 10+ yr experience in the trading business of industrial commodities "
              />
            </div>
            <div className="carousel-item ">
              <Carousel
                Image={Image2}
                Name="Shubham Jain"
                Role="Co-founder & CEO"
                Description="An IIT Bombay Alumnus, he has been associated with  TradeReboot  since inception. He has product and marketing understanding of TradeFinance"
              />
            </div>
            <div className="carousel-item ">
              <Carousel
                Image={Image3}
                Name="CA Vikram Pandya "
                Role="Fintech Advisor"
                Description="Vikram is a Director FinTech at SP Jain School of Global Management where he has designed Asia’s first interactive classroom led FinTech program featuring Blockchain, API Banking, AI/ML and IoT lab"
              />
            </div>
            <div className="carousel-item ">
              <Carousel
                Image={Image4}
                Name="Garima Agarwal "
                Role="Legal & Finance"
                Description="She is a Chartered Accountant having practice in valuation and due diligence for 5+ years in various fields"
              />
            </div>
            <div className="carousel-item ">
              <Carousel
                Image={Image5}
                Name="Arpit Kakadia "
                Role="Sales & Marketing Head"
                Description="Expertise in Banking Experience, and strong knowledge in the implementation of regulations related to foreign exchange (FX), Trade Finance products"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Team;
