export const ClientsData = [
  {
    Image: require("../assets/about-us/our clients/client-1.png"),
    Name: "Manufacturers",
  },
  {
    Image: require("../assets/about-us/our clients/client-2.jpg"),
    Name: "Wholesalers",
  },
  {
    Image: require("../assets/about-us/our clients/client-3.jpg"),
    Name: "Traders",
  },
  {
    Image: require("../assets/about-us/our clients/client-4.jpg"),
    Name: "Distributors",
  },
  {
    Image: require("../assets/about-us/our clients/client-5.jpg"),
    Name: "Importers & Exporters",
  },
];
