import React from "react";
import { Fade } from "react-reveal";
import HeadShake from 'react-reveal/HeadShake';

const Banner = ({ heading, question, img }) => {
  return (
    <div className="container-fluid Banner6-Bg px-0 ">
      <div className="CoverOverlay3 p-0">
        <div className="py-5 container ">
          <div className="row">
            <div className="col-xl-6 col-sm-10 col-lg-6 col-md-6 m-auto text-white">
              <HeadShake>
                <h2 className="mb-3">
                  {heading}
                  <span style={{ color: "rgb(101, 228, 253)" }}>
                    {" "}
                    Case Study
                  </span>{" "}
                </h2>
              </HeadShake>
              <p className="fs-6">{question}</p>
            </div>
            <Fade right><div className="col-xl-6 col-sm-10 col-lg-6 col-md-6 text-center m-auto Banner-section">
              <img
                src={img}
                alt="banner"
                className="img-fluid rounded-3"
                width="500px"
                height="500px"
              />
            </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
