import React, { Suspense, useState } from "react";
import { Helmet } from "react-helmet";
import Loader from "../common-components/Loader";
import ShortCut from "../common-components/ShortCut";
import Banner from "./components/Banner";
import EmarketCard from "./components/EmarketCard";
import ImproveLiquidity from "./components/ImproveLiquidity";
import Team from "./components/Team";
import {
  EMarketplaceData,
  MarkerPointData,
  CaseStudiesData,
  StatisticsData,
  StaticContentndfc,
  StaticContentusers,
  StaticContentbanks,
  StaticContentloan,
  ClientReview,
} from "./StaticData";

const Statistics = React.lazy(() => import("./components/Statistics"));
const StatisticsContent = React.lazy(() =>
  import("./components/StatisticsContent")
);
const Testimonials = React.lazy(() => import("./components/Testimonials"));
const CaseStudies = React.lazy(() => import("./components/CaseStudies"));

const HomeComponent = () => {
  const [Tab, setTab] = useState("NBFC");

  const addData = () => {
    switch (Tab) {
      case "NBFC":
        return StaticContentndfc;

      case "Users":
        return StaticContentusers;

      case "Banks":
        return StaticContentbanks;

      case "Credit Limit Issued":
        return StaticContentloan;
      default:
        return StaticContentndfc;
    }
  };
  return (
    <>
      <Helmet>
        <title>Trade Reboot Fin | Trade Finance</title>
        <meta
          name="description"
          content="Tradereboot is a finance marketplace to trade and finance working capital during export, so that you will not have to worry about the working and finance, just concentrate on expanding in international market."
        />
      </Helmet>
      <Banner />
      <EmarketCard
        EMarketplaceData={EMarketplaceData}
        Heading="Trade Finance"
        SubHeading="e-Marketplace"
      />
      <ImproveLiquidity MarkerPointData={MarkerPointData} />
      <Suspense fallback={<Loader />}>
        <CaseStudies CaseStudiesData={CaseStudiesData} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Statistics StatisticsData={StatisticsData} Tab={Tab} setTab={setTab} />{" "}
      </Suspense>
      <Suspense fallback={<Loader />}>
        <StatisticsContent DataConent={addData()} />
      </Suspense>
      <Team />
      <Suspense fallback={<Loader />}>
        <Testimonials ClientReview={ClientReview} />
      </Suspense>
      <ShortCut />
    </>
  );
};
export default HomeComponent;
