export default function validate(values) {

    let errors = {};
  
    if (!values.email) {
      errors.email = 'Field Missing'
    }
    if (!values.password) {
      errors.password = 'Field Missing';
    }
    console.log(errors);
    return errors;
}