import React, { Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router";
import Contactus from "../common-components/Contactus";
import Banner from "./components/Banner";
import {
  StoryData,
  OverviewData,
  OverviewData2,
  OverviewData3,
  OverviewData4,
} from "./StaticData";
import FoodImg from "../assets/casestudies/frozenfood.jpg";
import PolysterImg from "../assets/casestudies/polyester.jpg";
import GroceryImg from "../assets/casestudies/grocery.jpeg";
import ReadymadeImg from "../assets/casestudies/readymade.jpg";
import ShortCut from "../common-components/ShortCut";
import Loader from "../common-components/Loader";


const CaseOverview = React.lazy(() => import("./components/CaseOverview"));
const CaseStories = React.lazy(() => import("./components/CaseStories"));


const CaseStudies = () => {
  const location = useLocation();
  const [data, setData] = useState(OverviewData);
  const [heading, setHeading] = useState("Frozen Food Exporter");
  const [question, setQuestion] = useState(
    "How Amravati based frozen food exporter having a turnover of Rs 30 cr was able to improve cash flow through our digital trade finance platform."
  );
  const [img, setImg] = useState(FoodImg);

  const Casestudy = () => {
    switch (location.pathname) {
      case "/case-studies/frozen-food":
        setData(OverviewData);
        setHeading("Frozen Food Exporter");
        setQuestion(
          "How Amravati based frozen food exporter having a turnover of Rs 30 cr was able to improve cash flow through our digital trade finance platform."
        );
        setImg(FoodImg);
        break;
      case "/case-studies/polyester-dress":
        setData(OverviewData2);
        setHeading("Polyester dress ");
        setQuestion(
          "How Gurugram based polyester dress exporter having a turnover of Rs 15 cr was able to improve cash flow through tradereboot,digital trade finance platform."
        );
        setImg(PolysterImg);
        break;
      case "/case-studies/processed-food":
        setData(OverviewData3);
        setHeading("Grocery and Assorted  ");
        setQuestion(
          "How Navi Mumbai based Grocery and Assorted product exporter having a turnover of Rs 30 cr was able to improve cash flow through Tradereboot finance platform."
        );
        setImg(GroceryImg);
        break;
      case "/case-studies/readymade-food":
        setData(OverviewData4);
        setHeading("Cotton Apparels ");
        setQuestion(
          "How a Tamil Nadu based apparel exporter was able to improve cash flow through tradereboot platform."
        );
        setImg(ReadymadeImg);
        break;
      default:
        setData(OverviewData);
        setHeading("Frozen Food Exporter");
        setQuestion(
          "How Amravati based frozen food exporter having a turnover of Rs 30 cr was able to improve cash flow through our digital trade finance platform."
        );
        setImg(FoodImg);
        break;
    }
  };

  useEffect(() => {
    Casestudy();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Banner heading={heading} question={question} img={img} />
      <div className="container-fluid ">
        <div className="caseStudies row">
        <Suspense fallback={<Loader />}>
          <CaseOverview OverviewData={data} />
        </Suspense>
        
        <Suspense fallback={<Loader />}>
          <CaseStories StoryData={StoryData} />
        </Suspense>
          
        </div>
      </div>
      <Contactus />
      <ShortCut />
    </>
  );
};
export default CaseStudies;
