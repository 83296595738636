import React from "react";
import Image2 from "../../assets/about-us/sellers.jpg";

const Benefits = () => {
  return (
    <div className="container my-5">
      <h2 className="mb-5 text-center">
        <span style={{ color: "rgb(101, 228, 253)" }}> Benefits </span> of Using{" "}
        <span style={{ color: "rgb(101, 228, 253)" }}>TradeReboot</span>
      </h2>
      <ul
        className="nav nav-pills justify-content-center mb-3 gap-2 text-center"
        id="pills-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <button
            class="nav-link btn btn-primary px-5 text-light rounded-3 active"
            id="pills-lender-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-lender"
            type="button"
            role="tab"
            aria-controls="pills-lender"
            aria-selected="true"
          >
            Lenders
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            class="nav-link btn btn-primary px-5  text-light rounded-3"
            id="pills-seller-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-seller"
            type="button"
            role="tab"
            aria-controls="pills-seller"
            aria-selected="false"
          >
            Sellers
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            class="nav-link btn btn-primary px-5  text-light rounded-3"
            id="pills-buyers-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-buyers"
            type="button"
            role="tab"
            aria-controls="pills-buyers"
            aria-selected="false"
          >
            Buyers
          </button>
        </li>
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-lender"
          role="tabpanel"
          aria-labelledby="pills-lender-tab"
        >
          <div className="row justify-content-between mt-5">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <img src={Image2} alt="sellers" className="img-fluid rounded-3" />
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 my-auto">
              <ul className="MarkerPoint lh-lg">
                <li>
                  Access to new and profitable lending opportunities with little
                  or no investment
                </li>
                <li>Funding of a confirmed end-purpose</li>
                <li>Digital and standardized underwriting process</li>
                <li>Easy to scale-up</li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-seller"
          role="tabpanel"
          aria-labelledby="pills-seller-tab"
        >
          <div className="row justify-content-between mt-5">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <img src={Image2} alt="sellers" className="img-fluid rounded-3" />
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 my-auto">
              <ul className="MarkerPoint lh-lg">
                <li>
                  Payment is assured as long as you comply with the terms and
                  conditions of the letter of credit.
                </li>
                <li>
                  The credit risk is transferred from the buyer to the issuing
                  bank, which is obligated to pay even if the buyer goes
                  bankrupt.
                </li>
                <li>
                  You have easier access to financing and are able to transfer
                  all or part of the letter of credit to another party, e.g., to
                  purchase raw materials.
                </li>
                <li>
                  Collection time is minimized, as the letter of credit
                  accelerates payment of receivables, and foreign exchange risk
                  is eliminated when it is issued in the currency of your
                  country.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade "
          id="pills-buyers"
          role="tabpanel"
          aria-labelledby="pills-buyers-tab"
        >
          <div className="row  justify-content-between mt-5">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
              <img src={Image2} alt="sellers" className="img-fluid rounded-3" />
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 my-auto">
              <ul className="MarkerPoint lh-lg">
                <li>
                  You may not have the necessary collateral requirements or
                  relationship with the bank to get a letter of credit—but we
                  do.
                </li>
                <li>
                  You don't have to leave deposits with your suppliers, since
                  your letter of credit will be opened for the full amount of
                  the transaction—so your cash is not tied up or at risk while
                  your supplier can often borrow against a letter of credit.
                </li>
                <li>
                  You may significantly increase your ability to do business
                  with companies outside the U.S. by bolstering your financial
                  position with your suppliers.
                </li>
                <li>
                  You can build safeguards into the letter of credit, including
                  inspection of the goods and quality control, and set
                  production and delivery times. This gives you more quality
                  control over your goods.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Benefits;
