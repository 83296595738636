import React, { Suspense } from "react";
import Banner from "./components/Banner";
import { KeyPoints,  DiscountingProcessData } from "./StaticData";
import ShortCut from "../common-components/ShortCut";
import Loader from "../common-components/Loader";
import { Helmet } from "react-helmet";
import Fade from 'react-reveal/Fade';

const ExportInvoice = React.lazy(() => import("../common-components/ExportingInvoice"));
const DiscountingProcess = React.lazy(() => import("../common-components/DiscountingProcess"));
const Benifits = React.lazy(() => import("../common-components/Benifits"));

const ExportInvoiceDiscounting = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <title>Export Invoice Finance | Invoice Discounting | Trade Reboot Fin</title>
        <meta
          name="description"
          content="Export invoice finance or discounting is a funding source for exporters to improve their working capital.3 simple steps to apply- signup, buyer check & apply for finance."
        />
      </Helmet>

      <Banner />
      <Suspense fallback={<Loader />}>
        <ExportInvoice KeyPoints={KeyPoints}>
          <Fade top> <h3 className="mb-4">
            Export{" "}
            <span style={{ color: "rgb(101, 228, 253)" }}>
              Invoice Discounting
            </span>{" "}
          </h3>
          </Fade>
        </ExportInvoice>
      </Suspense>
      <div className="my-5">
      <Fade top>
        <h3 className="mb-5 text-center">
          Export{" "}
          <span style={{ color: "rgb(101, 228, 253)" }}>
            Invoice Discounting
          </span>{" "}
          Process
        </h3>
        </Fade>
        <Suspense fallback={<Loader />}>
          <DiscountingProcess DiscountingProcessData={DiscountingProcessData} />
        </Suspense>
       
      </div>

      {/* <div className="container-fluid bg-light mt-5">
        <div className="container py-5 ">
          <h3 className="mb-4 text-center">
            Working{" "}
            <span style={{ color: "rgb(101, 228, 253)" }}> Locations</span> with
            <span style={{ color: "rgb(101, 228, 253)" }}> Buyers</span>
          </h3>
          <Suspense fallback={<Loader />}>
            <MapChart />{" "}
          </Suspense>
        </div>
      </div> */}
      <Suspense fallback={<Loader />}>
        <Benifits>
          <Fade top> <h3 className="text-center mb-4">
            Export Factoring{" "}
            <span style={{ color: "rgb(101, 228, 253)" }}>Benefits </span>
          </h3></Fade>
        </Benifits>
      </Suspense>
      
      <ShortCut />
    </>
  );
};
export default ExportInvoiceDiscounting;
