import React from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import { Fade } from "react-reveal";
import { NavLink } from "react-router-dom";
import { platformUrl } from "../../../config";
import { ProcessData } from "../StaticData";

const Banner = ({ setTab, tab }) => {
  const iframeFun = () => {
    switch (tab) {
      case "Sign-Up/Login":
        return (

          <iframe
            src="https://www.youtube.com/embed/vjiyb13UDQ4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="img-fluid videoimg rounded-3"
          ></iframe>
        );
      case "Add Buyer Details":
        return (
          <iframe
            src="https://www.youtube.com/embed/Sc8obrU9xPw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="img-fluid videoimg  rounded-3"
          ></iframe>
        );
      case "Upload Invoice":
        return (
          <iframe
            src="https://www.youtube.com/embed/Sc8obrU9xPw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="img-fluid videoimg  rounded-3"
          ></iframe>
        );
      case "Process & Edit Profile":
        return (
          <iframe
            src="https://www.youtube.com/embed/Sc8obrU9xPw"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="img-fluid videoimg  rounded-3"
          ></iframe>
        );
      default:
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vjiyb13UDQ4"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>;
        break;
    }
  };

  return (
    <div className="container-fluid Banneronboarding-Bg px-0 ">
      <div className="CoverOverlay6 p-0">
        <div className="pt-5 pb-3 container-fluid">
          <div className="row pt-4 ">
            <div className="col-xl-6 col-sm-12 col-lg-6 col-md-6 my-auto pb-3">
              <Fade left><div className="text-center">{iframeFun()}</div></Fade> 
            </div>
            <div className="col-xl-5 col-sm-12 col-lg-5 col-md-6 text-white my-auto bannersection">
              <h2 className="mb-3">
                Our Guide to
                <span style={{ color: "rgb(101, 228, 253)" }}>
                  {" "}
                  Onboard TradeReboot
                </span>{" "}
                Platform ?
              </h2>

              <p className="col-xl-11 col-lg-11 col-md-11 col-sm-12 mb-5">
                TradeReboot can help import-export companies improve their
                short-term cash needs by selling their export invoices in return
                for an injection of cash.
              </p>
              {/* <NavLink to="/user-login" target="_blank"> */}
              <a href={platformUrl + "/login"} target="_blank">
                {" "}
                <button className=" btn btn-outline-info  px-4 py-2 fw-bold align-items-center ">
                  Apply Now
                  <HiChevronDoubleRight className="me-1 fs-5" />
                </button>
                </a>
              {/* </NavLink> */}
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            {ProcessData.map((key, index) => {
              return (
                <button
                  onClick={() => setTab(key, index)}
                  className={` col-12 col-xl-2 col-sm-5 col-md-5 col-lg-3 btn btn-outline-info px-5 py-3 me-2 mt-3 ${
                    tab === key && "btn-primary text-white shadow-sm"
                  }`}
                  key={index}
                >
                  {key}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
