import React from "react";
import BannerImg from "../../assets/supply chain/banner.jpeg";
import { HiChevronDoubleRight } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import HeadShake from 'react-reveal/HeadShake';
import { Fade } from "react-reveal";
import { platformUrl } from "../../../config";

const Banner = () => {
  return (
    <div className="container-fluid Banner4-Bg px-0 ">
      <div className="CoverOverlay2 p-0">
        <div className="py-5 container ">
          <div className="row py-5 ">
            <div className="col-xl-6 col-sm-10 col-lg-6 col-md-6 m-auto text-white">
            <HeadShake>
              <h1 className="mb-3">
                <span style={{ color: "rgb(101, 228, 253)" }}>
                  Supply Chain
                </span>{" "}
                Finance
              </h1>
            </HeadShake>
              
              <p className="mb-3 fs-5">
                <span style={{ color: "rgb(101, 228, 253)" }}> TradeFin</span>{" "}
                is initiated by the ordering party
              </p>
              <p className="col-xl-11 col-lg-11 col-md-11 col-sm-12 mb-5">
                Supply chain finance is an extension of the buyer’s accounts
                payable and is not considered financial debt. For the supplier,
                it represents a true sale of their receivables..
              </p>
              {/* <NavLink to="/user-login" target="_blank"> */}
              <a href={platformUrl + "/login"} target="_blank">
                <button className=" btn btn-outline-info px-4 py-2 fw-bold align-items-center ">
                  Apply Now
                  <HiChevronDoubleRight className="me-1 fs-5" />
                </button>
                </a>
              {/* </NavLink> */}
            </div>
            <div className="col-xl-6 col-sm-10 col-lg-6 col-md-6 m-auto Banner-section">
              <Fade right>
              <img
                src={BannerImg}
                alt="banner"
                className="img-fluid rounded-3"
              /></Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
