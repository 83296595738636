import React from "react";
import { NavLink } from "react-router-dom";
import BannerImg from "../../assets/about-us/banner.jpeg";
import { MdExplore } from "react-icons/md";
// import FinanceIcon from "../../assets/about-us/explore.svg";
import HeadShake from 'react-reveal/HeadShake';
import { Fade } from "react-reveal";
import { platformUrl } from "../../../config";

const Banner = () => {
  return (
    <div className="container-fluid Banner2-Bg px-0 ">
      <div className="CoverOverlay2 p-0">
        <div className="py-5 container ">
          <div className="row py-5 ">
            <div className="col-xl-6 col-sm-10 col-lg-6 col-md-6 m-auto text-white">
              <HeadShake>
                <h1 className="mb-3">
                  About <span style={{ color: "rgb(101, 228, 253)" }}>Us</span>{" "}
                </h1>
              </HeadShake>
              <p className="mb-3 fs-5">
                Reforming{" "}
                <span style={{ color: "rgb(101, 228, 253)" }}>
                  Liquidity & Through TradeReboot Solution{" "}
                </span>{" "}
              </p>
              <p className="col-xl-11 col-lg-11 col-md-11 col-sm-12 mb-5">
                We help MSME to forecast, monitor and free up cash through our
                innovative, robust trade finance platform
              </p>
              {/* <NavLink to="/user-login" target="_blank"> */}
              <a href={platformUrl + "/login"} target="_blank">
                <button className=" btn btn-outline-info px-4 py-3 fw-bold align-items-center">
                  <MdExplore className="fs-5 me-1" />
                  Explore More
                </button>
              </a>
              {/* </NavLink> */}
            </div>
            <div className="col-xl-6 col-sm-10 col-lg-6 col-md-6 m-auto Banner-section">
            <Fade right>
              <img
                src={BannerImg}
                alt="BannerImg"
                className="img-fluid rounded-3"
              />
              </Fade>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
