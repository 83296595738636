// const env = "local"
const env = "dev"
// const env = "prod"

function getPlatFormUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://dev.app.tradereboot.com"
  }
  if (env === "prod") {
    return "https://app.tradereboot.com"
  }
}

function getPlatFormBackendUrl() {
  if (env === "local") {
    return "http://localhost:3000"
  }
  if (env === "dev") {
    return "https://dev-server.fob.sofodev.co"
  }
  if (env === "prod") {
    return "https://server.tradereboot.com"
  }
}

// function getTradeFinBackendUrl() {
//   if (env === "local") {
//     return "http://localhost:3004"
//   }
//   if (env === "dev") {
//     return "https://webfindev-backend.sofodev.co"
//   }
//   if (env === "prod") {
//     return "https://backend.traderebootfin.com"
//   }
// }

export const platformUrl = getPlatFormUrl()
export const platformBackendUrl = getPlatFormBackendUrl()
export const tradeFinBackendUrl = getPlatFormBackendUrl()