import { AiOutlineUserAdd, AiFillBank } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
import { MdApproval } from "react-icons/md";
import Image1 from "../assets/nbfc images/NBFC.jpg";
import Image2 from "../assets/nbfc images/NBFC-2.jpeg";
import Image3 from "../assets/nbfc images/NBFC-3.jpeg";
import Image4 from "../assets/nbfc images/NBFC-1.jpeg";
import Client1 from "../assets/testimonials/client-1.png";
import Client2 from "../assets/testimonials/client-2.png";
import Client3 from "../assets/testimonials/client-3.png";

export const EMarketplaceData = [
  {
    heading: "Export Invoice Discounting",
    Image: require("../assets/Banner/export-factoring.jpeg"),
    Paragraph:
      "Access Cash against Your Outstanding Export Invoices Through Export Factoring. Accelerate your Business Cash  flow Cycle, Get Collateral-Free Export Finance",
    Link: "/export-invoice",
    classnames: "card-body py-0 marketplaceparaheight",
  },
  {
    heading: "Supply chain finance",
    Image: require("../assets/Banner/Freight-Finance.jpeg"),
    Paragraph:
      "Supply chain finance is a type of supplier finance solution that companies can use to offer early payments to their suppliers based on approved  export invoices. ",
    Link: "/supply-chain",
    classnames: "card-body py-0 marketplaceparaheight",
  },
  {
    heading: "Export LC Bill Discounting",
    Image: require("../assets/Banner/Lc-Discounting.jpeg"),
    Paragraph:
      "Smartest way to discount your letter of credit. Compare & choose what fits you best. More than 20+ Banks / NBFCs listed. Select from the cheapest LC  bill discounting interest rates",
    Link: "/export-lc-discounting",
    classnames: "card-body py-0 marketplaceparaheight",
  },
  {
    heading: "Freight Finance",
    Image: require("../assets/Banner/Lc-Opening.jpeg"),
    Paragraph:
      "We offer just-in-time cash flow, or freight bill factoring through trade reboot marketplace to the transportation industry",
    Link: "/freight-finance",
    classnames: "card-body py-0 marketplaceparaheight",
  },
];

export const MarkerPointData = [
  "Our trade finance solution can help clients to identify, evaluate and improve cash flow and working capital requirements through our globally connected lenders network.",
  "Our Trade Finance platform can help import- export companies to improve their cash flows which helps them to improve their quality of services both internally and externally",
  "Our team assist import-export companies  in their transition to a cash-focused culture by identifying areas  for improvement and also help them to address immediate and short term liquidity challenges.",
  "Trade reboot help import-export companies to Forecast, Monitor & Free Up Cash through our Innovative, Robust Trade Finance platform",
];

export const CaseStudiesData = [
  {
    Title: "Frozen Foods, Amravati - Maharashtra",
    Description:
      "Our Trade Finance Platform helped the supplier to get limits on their Europe based buyers within 48 hours, after getting fast funding through our platform now they can export goods to netherland and france.",
    AccId: "AccordionOne",
    HeadingID: "HeadingOne",
    CollapseId: "CollapseOne",
    Link: "case-studies/frozen-food",
  },
  {
    Title: "Manufacturer of Polyester Dress, Gurgaon - Haryana ",
    Description:
      " Exporters of polyester dress grew their business in the UK. Tradeeboot helped them to get limits for the UK based buyer so that exporters can extend credits to pursue new orders. ",
    AccId: "AccordionTwo",
    HeadingID: "HeadingTwo",
    CollapseId: "CollapseTwo",
    Link: "case-studies/polyester-dress",
  },
  {
    Title: "Processed Foods, Vashi - Navi Mumbai",
    Description:
      "Tradereboot provided quick funding of 0.25 Million USD now Exporter can increase sales volume with Buyer and buyer gets enhancement in credit so a win win scenario for both the parties.",
    AccId: "AccordionThree",
    HeadingID: "HeadingThree",
    CollapseId: "CollapseThree",
    Link: "case-studies/processed-food",
  },
  {
    Title: "Manufacturer Readymade Garment Karur - Tamil Nadu",
    Description:
      "Tradereboot provided a six-figure trade finance facility to help an apparel exporter expand its business with American buyers.",
    AccId: "AccordionFour",
    HeadingID: "HeadingFour",
    CollapseId: "CollapseFour",
    Link: "case-studies/readymade-food",
  },
];

export const StatisticsData = [
  {
    id: "users",
    name: "Users",
    icon: <AiOutlineUserAdd className="fs-1" />,
    count: "500+",
  },
  {
    id: "banks",
    name: "Banks",
    icon: <AiFillBank className="fs-1" />,
    count: "20+",
  },
  {
    id: "nbfc",
    name: "NBFC",
    icon: <BiBuildingHouse className="fs-1" />,
    count: "5+",
  },
  {
    id: "loan",
    name: "Credit Limit Issued",
    icon: <MdApproval className="fs-1" />,
    count: "$32 Million",
  },
];

export const OurTeamStaticData = [
  {
    Image: require("../assets/our team/Team-mem-1.jpg"),
    Name: "Yahya R. Makarim",
    Role: "CEO & President",
    Description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
  {
    Image: require("../assets/our team/Team-mem-1.jpg"),
    Name: "Yahya R. Makarim",
    Role: "CEO & President",
    Description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
  },
];

export const StaticContentndfc = {
  HeadingData: "Non-Bank Financial Institution",
  Description:
    "NBFC(non-bank financial company) is a financial institution that facilitate bank-related financial servicessuch as managing portfolios of stocks and shares, invoice discounting services etc.TradeReboot Finance has integrated with 5+ more NBFCs. This makes the whole trade process much easier.So generating KYC report, Credit Analysis Report, Credit Rating will be done on our platform that helps in finance limit, fund amount, interest rate, and percentage of trade to be funded, other parameters and T&C. so this makes whole trade process simpler and less time consuming.",

  content1: [
    {
      CompanyLogo: Image1,
      Name: "Incomelend",
      // classname: "",
    },
    {
      CompanyLogo: Image2,
      Name: "Modifi",
      // classname: "Circle2",
    },
    // {
    //   CompanyLogo: Image3,
    //   Name: "Tradewind",
    //   // classname: "Circle3",
    // },

    {
      CompanyLogo: Image4,
      Name: "Stenn",
      classname: "img-fluid",
    },
  ],
};

export const StaticContentusers = {
  HeadingData: "Users",
  Description:
    "TradeReboot successfully associated with 500+ more users by providing access to a wide range offinancing solutions. Our clients’ evolvingin trade/export finance activities required a digital platform that can offer them a support throughout thetrade cycle for cross border trade deals. With the team of experts specializing in international trade finance, we help them to get access of working capital faster so that they can deliver on their business opportunities.",
  content1: [
    {
      CompanyLogo: Image1,
      Name: "Incomelend",
      // classname: "",
    },
    {
      CompanyLogo: Image2,
      Name: "Modifi",
      // classname: "Circle2",
    },
    // {
    //   CompanyLogo: Image3,
    //   Name: "Tradewind",
    //   // classname: "Circle3",
    // },

    {
      CompanyLogo: Image4,
      Name: "Stenn",
      classname: "img-fluid",
    },
  ],
};

export const StaticContentbanks = {
  HeadingData: "Banks",
  Description:
    "Banks play an important role in the export business as it offersfacilities for receiving payments and current accounts.Therefore, it is important to choose the right bank having Foreign Trade capabilities.At TradeReboot Finance we have integrated with 20+ more banks to enable trade/export finance process more soothing. With our digital platform, exporters can get access to immediate working capital with minimal documentation and zero hard collateral. We provide an option to fund you via the multiple banks.",
  content1: [
    {
      CompanyLogo: Image1,
      Name: "Incomelend",
      // classname: "",
    },
    {
      CompanyLogo: Image2,
      Name: "Modifi",
      // classname: "Circle2",
    },
    // {
    //   CompanyLogo: Image3,
    //   Name: "Tradewind",
    //   // classname: "Circle3",
    // },

    {
      CompanyLogo: Image4,
      Name: "Stenn",
      classname: "img-fluid",
    },
  ],
};

export const StaticContentloan = {
  HeadingData: "Credit Limit Issued",
  Description:
    "Export loans are provided to overseas exporters/importers and financial institutions to support finance exports, these loans can be in the form of term loan , pre/ post shipment credit and working capital.With seamless integration and digital platform, TradeReboot Fin has provided credit limit to $32 Million via its export factoring solution. This has done because of our digital first and a trust based approach towards our clientele. Our team of experts specializing in international trade finance worked closely with the client team to smoothen their digital transformation journey in digital trade at every level.",
  content1: [
    {
      CompanyLogo: Image1,
      Name: "Incomelend",
      // classname: "",
    },
    {
      CompanyLogo: Image2,
      Name: "Modifi",
      // classname: "Circle2",
    },
    // {
    //   CompanyLogo: Image3,
    //   Name: "Tradewind",
    //   // classname: "Circle3",
    // },

    {
      CompanyLogo: Image4,
      Name: "Stenn",
      classname: "img-fluid",
    },
  ],
};
export const ClientReview = [
  {
    Client: Client1,
    Name: "Mr. M V Sreenivas",
    description:
      "It is a real pleasure to get into this platform of Trade Reboot and the procedure involved in getting the approvals are really hassle free procedures and I am amazed that the time taken to get this process sanctioned is absolutely much much better than other financial services.",
  },
  {
    Client: Client2,
    Name: "Mr. Aditya Bhoot",
    description:
      "Very user friendly platform. Most of the information is easily accessible. No need to followup with team as the required information is timely updated.",
  },
  {
    Client: Client3,
    Name: "Mr. Sekar Kandaswami",
    description:
      "We have been working with you few days only & the experience was good with you @ TradeReboot.",
  },
];
