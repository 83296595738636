import React from "react";
import { NavLink } from "react-router-dom";
import Pulse from "react-reveal/Pulse"

const Contactus = () => {
  return (
    <div className="bg-light border-bottom Contactusbg">
      <div className=" OverlayVision">
        <div className="container col-xl-6 col-lg-7 col-md-11 col-sm-11 mx-auto text-center text-white">
          <Pulse>
            <h3 className="mb-0" style={{ paddingTop: "115px" }}>
              Looking For{" "}
              <span style={{ color: "rgb(101, 228, 253)" }}>
                Finance Partner?{" "}
              </span>
            </h3>
          </Pulse>
          <p className="my-3">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content.
          </p>
          <button className="btn btn-primary rounded-3 px-4">
            <NavLink
              to="/contact-us"
              className="text-decoration-none text-light"
            >
              Contact us
            </NavLink>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Contactus;
