import React from "react";
import BannerImg from "../../assets/Banner/Banner-img3.jpeg";
import { AiFillDollarCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import HeadShake from 'react-reveal/HeadShake';
import { Fade } from "react-reveal";
import { platformUrl } from "../../../config";


const Banner = () => {
  return (
    <div className="container-fluid Banner-Bg px-0 ">
      <div className="CoverOverlay p-0">
        <div className="py-5 container ">
          <div className="row py-5">
            <div className="col-xl-6 col-sm-10 col-lg-6 col-md-6 m-auto text-white">
              <HeadShake>
                <h1 className="mb-3">
                  TradeReboot{" "}
                  {/* <span style={{ color: "rgb(101, 228, 253)" }}>Fin</span>{" "} */}
                </h1>
                </HeadShake> 
              <p className="mb-3 fs-4">
                <span style={{ color: "rgb(101, 228, 253)" }}>
                  {" "}
                  Trade Finance Reforming Export Liquidity
                </span>{" "}
                and{" "}
                <span style={{ color: "rgb(101, 228, 253)" }}>
                  {" "}
                  Working Capital
                </span>{" "}
                one step at a time
              </p>
              <p className="me-5 mb-5">
                We help MSME to forecast, monitor and free up cash through our
                innovative, robust trade finance platform
              </p>
              {/* <NavLink to="/user-login" target="_blank"> */}
              <a href={platformUrl + "/login"} target="_blank">
                <button className=" btn btn-outline-info px-4 py-3 fw-bold align-items-center">
                  <AiFillDollarCircle className="fs-5 me-1" />
                  <span className="pt-2">Get Credit Line in 24hr</span>
                </button>
                </a>
              {/* </NavLink> */}
            </div>
            <Fade right>
            <div className="col-xl-6 col-sm-10 col-lg-6 col-md-6 m-auto Banner-section">
              <img
                src={BannerImg}
                alt="banner"
                className="img-fluid rounded-3" />
            </div>
            </Fade>
          </div>
        </div>
      </div>

    </div>


  );
};
export default Banner;
