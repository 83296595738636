import React, { Suspense } from "react";
import ExportInvoice from "../common-components/ExportingInvoice";
import Banner from "./components/Banner";
import { KeyPoints, } from "./StaticData";
import { DiscountingProcessData } from "../ExportInvoice Discounting/StaticData";
import ShortCut from "../common-components/ShortCut";
import { Helmet } from "react-helmet";
import Fade from 'react-reveal';
import Loader from "../common-components/Loader";


const Benifits = React.lazy(() => import("../common-components/Benifits"));
const DiscountingProcess = React.lazy(() => import("../common-components/DiscountingProcess"));

const ExportLcDiscounting = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <title>Export LC Bill Discounting & Trade Services | Trade Reboot Fin</title>
        <meta
          name="description"
          content="Export LC Bill Discounting refers sending letter of credit bill to overseas bank/buyers through exporter bank to collect payment.3 simple steps- sign up, uploads LC and get payment."
        />
      </Helmet>

      <Banner />
      <ExportInvoice KeyPoints={KeyPoints}>
        <Fade top>
          <h3 className="mb-4">
            Export{" "}
            <span style={{ color: "rgb(101, 228, 253)" }}>
              LC Bill Discounting
            </span>{" "}
          </h3></Fade>
      </ExportInvoice>
      <div className="my-5">
      <Fade top>
        <h3 className="mb-5 text-center">
          Export{" "}
          <span style={{ color: "rgb(101, 228, 253)" }}>
            LC Discounting
          </span>{" "}
          Process
        </h3>
        </Fade>
        <Suspense fallback={<Loader />}><DiscountingProcess DiscountingProcessData={DiscountingProcessData} /></Suspense>
        
      </div>
      <Suspense fallback={<Loader />}>
        <Benifits>
          <Fade top>
            <h3 className="text-center mb-4">
              Export LC Bill Discounting{" "}
              <span style={{ color: "rgb(101, 228, 253)" }}>Benefits </span>
            </h3>
          </Fade>
        </Benifits>
      </Suspense>
    
      
      <ShortCut />
    </>
  );
};
export default ExportLcDiscounting;
