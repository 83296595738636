/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/logo_1.png";
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { IoMailUnreadOutline } from "react-icons/io5";
import { RiHomeSmile2Fill } from "react-icons/ri";
import Jump from "react-reveal/Jump";
import Flash from "react-reveal/Flash";
import Fade from "react-reveal/Fade";

const Footer = () => {
  return (
    <div className="bg-light">
      <div className="container py-5">
        <div className="row align-items-center">
          <div className="col-xl-2 col-sm-8 col-md-8 col-lg-2 mx-auto text-center">
            <img
              src={Logo}
              alt="logo"
              className="img-fluid "
              height="150px"
              width="150px"
            />
          </div>
          <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto">
            <div>
              <Jump>
                <h3>
                  Reforming{" "}
                  <span style={{ color: "rgb(101, 228, 253)" }}>
                    {" "}
                    Liquidity & Working Captial
                  </span>
                </h3>
              </Jump>

              <p>Finally, a partner that handles the heavy lifting for you.</p>
            </div>
            <div>
              <nav className=" bg-light">
                <div className="">
                  <div className="row">
                    <div className="col-xl-9 col-lg-9 col-md-11 col-sm-11">
                      <ul className="nav">
                        {FooterData.map((key, index) => {
                          return (
                            <li className="nav-item" key={index}>
                              <NavLink
                                className="nav-link active fw-semibold ps-0"
                                aria-current="page"
                                to={key.Link}
                              >
                                {key.Name}
                              </NavLink>
                            </li>
                          );
                        })}
                        <li className="nav-item">
                          <a
                            className="nav-link nav-menu fs-6 text-dark text-nowrap"
                            href="./Privacy_&_Cookie_Policy.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                    <Fade right>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-10 text-end cursor">
                        <div className="d-flex gap-2 justify-content-end">
                          <a
                            href="https://www.facebook.com/TradeReboot"
                            target="_blank"
                            rel="noreferrer"
                            hrefLang="en-us"
                          >
                            {" "}
                            <FaFacebookSquare className="fs-4 CustomColor IconAnimation" />
                          </a>
                          <a
                            href="https://www.instagram.com/tradereboot_/?hl=en"
                            target="_blank"
                            rel="noreferrer"
                            hrefLang="en-us"
                          >
                            <FaInstagram className="fs-4 CustomColor IconAnimation" />
                          </a>
                          <a
                            href="https://www.linkedin.com/company/trade-reboot/"
                            target="_blank"
                            rel="noreferrer"
                            hrefLang="en-us"
                          >
                            <FaLinkedin className="fs-4 CustomColor IconAnimation" />
                          </a>
                        </div>
                        <small className="text-secondary ">
                          All Right Reserved.
                        </small>
                      </div>
                    </Fade>
                  </div>
                </div>
                <div className="pt-5">
                  <Flash>
                    <div className="d-flex ">
                      <div className="col-3">
                        <h5
                          className="fw-bold"
                          style={{ color: "rgb(30 163 174)" }}
                        >
                          START A CONVERSTATION
                        </h5>
                      </div>
                      <div className="col-9">
                        <hr />
                      </div>
                    </div>
                  </Flash>
                  <div className="row align-items-center">
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 py-3">
                      <Fade bottom>
                        <div className="d-flex align-items-center SocialMediaFooter">
                          <a
                            href="#"
                            className=" mb-0 cursor p-1 px-2"
                            hrefLang="en-us"
                            rel="alternate"
                          >
                            <IoIosCall style={{ marginTop: "-3px" }} />
                          </a>
                          <span className="ms-2 small">8450969138</span>
                        </div>
                      </Fade>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6  py-3">
                      <Fade bottom>
                        <div className="d-flex align-items-center SocialMediaFooter">
                          <a
                            href="#"
                            className=" mb-0 cursor p-1 px-2"
                            hrefLang="en-us"
                            rel="alternate"
                          >
                            <IoMailUnreadOutline
                              style={{ marginTop: "-3px" }}
                            />
                          </a>
                          <span className="ms-2 small">
                            info@tradereboot.com
                          </span>
                        </div>
                      </Fade>
                    </div>
                    <Fade bottom>
                      <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6  py-3">
                        <div className="d-flex align-items-center SocialMediaFooter">
                          <a
                            href="#"
                            className=" mb-0 cursor p-1 px-2"
                            hrefLang="en-us"
                            rel="alternate"
                          >
                            <RiHomeSmile2Fill style={{ marginTop: "-3px" }} />
                          </a>
                          <span className="ms-2 small">
                            511/512 Atlanta Estate, Goregaon East, Opp.Westin
                            Hotel, Western Express Highway,Mumbai 400063
                          </span>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

const FooterData = [
  {
    Name: "Home",
    Link: "/",
  },
  {
    Name: "Services",
    Link: "/about-us",
  },
  {
    Name: "Case Studies",
    Link: "/case-studies/frozen-food",
  },
  {
    Name: "About Us",
    Link: "/about-us",
  },
  {
    Name: "Contact Us",
    Link: "/contact-us",
  },
];
