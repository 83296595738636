import twitter from "../assets/casestudies/twitter.png";
import facebook from "../assets/casestudies/facebook.png";
import instagram from "../assets/casestudies/instagram.png";
import DressImg from "../assets/casestudies/product-2.jpg";
import FoodImg from "../assets/casestudies/product-1.jpg";
import GroceryImg from "../assets/casestudies/product-3.jpg";
import ReadymadeImg from "../assets/casestudies/product-4.jpg";

export const StoryData = [
  {
    Heading: "Designing Tools",
    Image: twitter,
    Description:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!",
    Link: "https://twitter.com/trade_reboot",
  },
  {
    Heading: "Designing Tools",
    Image: instagram,
    Description:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!",
    Link: "https://www.instagram.com/",
  },
  {
    Heading: "Designing Tools",
    Image: facebook,
    Description:
      " Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae dolores deserunt ea doloremque natus error, rerum quas odio quaerat nam ex commodi hic, suscipit in a veritatis pariatur minus consequuntur!",
    Link: "https://www.facebook.com/TradeReboot",
  },
];

export const OverviewData = {
  Para1:
    "M/s MDA Agrocot is a growing company in the frozen food industry. They manufacture highest quality of frozen goods with precision monitoring and packaging standards which are in demand throughout the year worldwide. During their rapid expansion they were facingthe severe cash crunch because the number of orders are increased but due to the long cycle of credit cash flow, the liquidity were not maintained in import-export or any trade deals. The management was aware of the LC bill discounting process and wanted to expand into the European and Canadian markets aggressively because of the rising demands for frozen foods like mango pulp, frozen corn, etc. The management has fully utilized their working capital available with the couple of banks because of the liquidity crunch in market due to impact of covid-19, this had provoked the M/s MDA Agrocot to actively explore for the options of alternative financing, possibly without collateral and more importantly at a cheaper interest rate.",
  Para2:
    "Traderebootintegrated withM/s MDA Agrocot that moment with innovative digital trade finance platform. Our talented team of sales and marketing, got into touch with the management, and highlighted the digital trade lifecycle withtrade finance important financing/factoring instruments. One such instrument isexport factoring available with TradeReboot Fin automated digital tradefinanceplatform, connecting the exporters, importers and the financial institutions (NBFCs, Banks, moneylenders, etc.) on a single platform. Initially the management of M/s MDA Agrocot was hesitant about the digital export factoring system, but our consistent efforts along with our commitment to bring value to our clients brought a breakthrough with the firstdigital trade getting financed. Our team made held various one to one meets with the key personnel's to understand the concerns raise at the different steps of export factoring or  invoice financing, and ensured to provide utmost support and assistance on how the product works by answering all their queries swiftly and gathering their trust along the process. To maintain transparency we even provided them a virtualdigital trade demo highlighting the importance of our automated digital trade financemarketplace.",
  Listdata: [
    "Easy and free sign up for the exporter. Fill the basic details and you are directly onboarded on the platform",
    "Approving your buyer was never this easy. Just upload basic details of your buyer, over a secure and encrypted network. Only you and the financial instruments get to see the details you upload.",
    "Our partnered NBFCs will check the credibility of the buyer along with the authenticity of the uploaded documents and respond within 24-48 hrs.",
    "NBFCs approve credit linefor buyer and issue finance upto 90% against export invoice with no hidden charges involved",
    "The day buyer pays for the exportinvoice, the NBFCs remit the remaining 10% to the exporter",
  ],
  Para3:
    "The above process was the quickest and safest way for the management which helped them to get the credit limits approved for their buyer in a timespan of mere 24-48 hours after generating theexport invoice. The moment M/s MDA Agrocot got their buyer credit limits approved, they forwarded the proposal to the buyer and the buyer gave his acknowledgement to the proposed export factors over the digital channel. This came out as a win-win situation for both the M/s MDA Agrocot and the buyer, as the buyer got their products without paying for an advance or issuing LC(letter of credit), and the M/s MDA Agrocot also enjoyed a collateral free finance against theirexport invoices.",
  Para4:
    "With the help of our digital trade financeplatform, M/s MDA Agrocot was able to improve their cash flow without collateral and also got increment in the orders from the buyer because the management was able to extend credit terms with the buyer.",
  Para5:
    "Having experienced the digital tradejourney compelled with our zeal to excellence, M/s MDA Agrocot had been able to onboard 5 new buyers on our digital trade finance platform and got his credits approvals in a matter of couple of hours. They are now expanding their businesses in Canada, USA and Europe which would not have been possible with the traditional cash flow cycle. The TradeReboot Finance marketplace is a state of the art trade finance platform empowering the cross border trade with trust and technology.",
  Image: FoodImg,
};
export const OverviewData2 = {
  Para1:
    "M/s Rijul International is a rising company in the manufacturing of polyester dresses. They manufacture highest quality of polyester goods with precision monitoring and the high class of material standards, which are in growing demand throughout the year worldwide. During their initial days of export-import business they were facing severe cash crunch due to the long time cycle of credit cash flow in the export-import business. The management was aware of the export factoring procedure and had a vision to expand into the European markets especially UK, aggressively because of their extensive clientele base. The management applied to get their credit limit approved for the buyers with an export factor that was introduced to them by a broker. But after many tries and a heavy commission spent, they didn't have the success to get a worthy credit limit for their buyer, at a cheaper interest rate.",
  Para2:
    "We integrated with M/s Rijul International that moment with innovative digital trade finance platform. Our talented team of sales and marketing, got into touch with the management, and highlighted the digital trade lifecycle withtrade finance important financing/factoring instrument. One such instrument isexport factoring available with TradeReboot Fin automated digital tradefinanceplatform, connecting the exporters, importers and the financial institutions (NBFCs, Banks, moneylenders, etc.) on a single platform. Our team made held various one to one meets with the key personnel's to understand theconcerns raise at  the different steps of export factoring or invoice financing, and ensured to provide utmost support and assistance on how the product works by answering all their queries swiftly and gathering their trust along thedigital tradeprocess. To maintain transparency we even provided them a virtual digital trade finance demo highlighting the importance of our automated digital trademarketplace. The management was not aware about the trade finance marketplace at that time and was thrilled to know about ourdigital trade financeplatform providing access to international NBFCs in a few clicks.",
  Listdata: [
    "Easy and free sign up for the exporter. Fill basic details and you are directly onboarded on the platform",
    "Approving your buyer was never this easy. Just upload basic details of your buyer, over a secure and encrypted network. Only you and the financial instruments get to see the details you upload.",
    "Our partnered NBFCs will check the credibility of the buyer along with the authenticity of the uploaded documents and respond within 24-48 hrs.",
    "NBFCs approve credit line for buyer and issue finance upto 90% against export invoice with no hidden charges involved",
    "The day buyer pays for the export invoice, the NBFCs remit the remaining 10% to the exporter",
  ],
  Para3:
    "The above process was the quickest and safest way for the management which helped them get the credit limits approved for their buyer in a timespan of mere 24-48 hours after generating theexport invoices. The moment M/s Rijul International got their buyer credit limits approved, they forwarded the proposal to the buyer and the buyer gave his acknowledgement to the proposed export factors over the digital channel. This came out as a win-win situation for both the M/s Rijul International and the buyer, as the buyer got their products without paying for an advance or issuing LC(letter of credit), and the M/s Rijul International also enjoyed a collateral free credit for theirexport invoices.",
  Para4:
    "With the help of a digital trade platform, M/s Rijul International was able to improve their cash flow without collateral and also got an increase in the orders from the buyer because the management was able to extend credit terms with the buyer.",
  Para5:
    "Having experienced the digital trade journey compelled with our zeal to excellence, M/s Rijul International had been able to expand his business by 2x and on weekly basis they are shipping goods to their buyers. The management is able to process more orders because of the extended credit limits with the buyer and the moment they were discounting theexport invoices from the factors on our automated digital trade finance platform, the management uses theexport factoring and invoice financingfacility on new orders. Presence of a fully digital trade platform facilitating collateral-free working capital, M/s Rijul International is now able to rotate the whole cycle of supply chain and finance to expand theirexport-import business. The TradeReboot Finance marketplace is a state of the art trade finance platform empowering the cross border trade with trust and technology.",
  Image: DressImg,
};
export const OverviewData3 = {
  Para1:
    "Galaxy International is a 10 year old partnership firm founded in Mumbai with an aim to provide world class Spices, Pulses & Lentils, Nuts & dry fruits, dehydrated range of products from sourcing Indian SME's. Galaxy International has created a single window solution for multi product requirement caters to international markets like USA, CANADA & UK. Over the years sales have increased due to demand from various commercial kitchens, hotels and restaurants to provide such demand galaxy international would require fast cash traditionally they were dependent onexportloansprovided by bank which was backed by collateral, existing limits were not enough to fulfill the demand also the business model was such that they need procure food products from various small entities which general do export businesson cash not on credit. Galaxy international supplychainwas stuck due to lack of collateral and working capital and were actively looking out for new alternative fortrade finance which was based on performance not on collateral.",
  Para2:
    "Tradereboot was introduced to Galaxy International by our existing client reference, Galaxy International was aware about the export factoring but he was under impression that it can be possible for those exporters who are located in countries like USA and Europe but wasn't aware that it can be possible for Indian exporters. When our team exposed them to our innovative trade finance platform and highlighted the benefits of how small MSMEs from India can take benefit of such financial services, they were excited to try the new age of fintech revolution. We assisted them to quickly get them onboard and add relevant details on the digital platform so that they can get financed for their buyer approved from partnered financier on platform. ",
  Listdata: [
    "Easy and free sign up for the exporter. Fill basic details and you are directly onboarded on the platform",
    "Approving your buyer was never this easy. Just upload basic details of your buyer, over a secure and encrypted network. Only you and the financial instruments get to see the details you upload.",
    "Our partnered NBFCs will check the credibility of the buyer along with the authenticity of the uploaded documents and respond within 24-48 hrs.",
    "NBFCs approve credit line for buyer and issue finance upto 90% against export invoice with no hidden charges involved",
    "The day buyer pays for the export invoice, the NBFCs remit the remaining 10% to the exporter",
  ],
  Para3:
    "The above process was the quickest and safest way for the management which helped them get the credit limits approved for their buyer in a timespan of mere 24-48 hours after generating theexport invoices. The moment M/s Rijul International got their buyer credit limits approved, they forwarded the proposal to the buyer and the buyer gave his acknowledgement to the proposed export factors over the digital channel. This came out as a win-win situation for both the M/s Rijul International and the buyer, as the buyer got their products without paying for an advance or issuing LC(letter of credit), and the M/s Rijul International also enjoyed a collateral free credit for theirexport invoices.",
  Para4:
    "The above process was so convenient for Galaxy International to improve their cash flow and their buyer also supported them for new finance arrangement so that they can fulfill new orders in pipeline. This wouldn't have been possible earlier for the M/s Galaxy International to close new orders on time due to lack of working capital. They were used to pay cash to their sources for maintaining cash flowfrom varied sources, as well as to procure material from new source. With the advent of TradeReboot platform they are now availingTrade Finance(Invoice Discounting/ Invoice Financing/ Export Financing)services from our partner lenders without any collateral, helping them in improving their cash flow and helping them unlock the stuck cash which was locked in transit time.",
  Para5:
    "M/s Galaxy International was able to manage and improve their supplychain with the help of Tradereboot finance digital marketplace. They have access to alternate source of funds directly from their offices, rather than depending on traditional collateral backed finance from banks. M/s Galaxy International was able to increase their sales by 2x because of the increased working capital by way of discounting/ financingthe invoices, and utilizing the funds for new orders in the pipeline. The TradeReboot Finance marketplace is a state of the art trade finance platform empowering the international trade with trust and technology.",
  Image: GroceryImg,
};
export const OverviewData4 = {
  Para1:
    "Galaxy International is a 10 year old partnership firm founded in Mumbai with an aim to provide world class Spices, Pulses & Lentils, Nuts & dry fruits, dehydrated range of products from sourcing Indian SME's. Galaxy International has created a single window solution for multi product requirement caters to international markets like USA, CANADA & UK. Over the years sales have increased due to demand from various commercial kitchens, hotels and restaurants to provide such demand galaxy international would require fast cash traditionally they were dependent onexportloansprovided by bank which was backed by collateral, existing limits were not enough to fulfill the demand also the business model was such that they need procure food products from various small entities which general do export businesson cash not on credit. Galaxy international supplychainwas stuck due to lack of collateral and working capital and were actively looking out for new alternative fortrade finance which was based on performance not on collateral.",
  Para2:
    "Tradereboot was introduced to Galaxy International by our existing client reference, Galaxy International was aware about the export factoring but he was under impression that it can be possible for those exporters who are located in countries like USA and Europe but wasn't aware that it can be possible for Indian exporters. When our team exposed them to our innovative trade finance platform and highlighted the benefits of how small MSMEs from India can take benefit of such financial services, they were excited to try the new age of fintech revolution. We assisted them to quickly get them onboard and add relevant details on the digital platform so that they can get financed for their buyer approved from partnered financier on platform. ",
  Listdata: [
    "Easy and free sign up for the exporter. Fill basic details and you are directly onboarded on the platform",
    "Approving your buyer was never this easy. Just upload basic details of your buyer, over a secure and encrypted network. Only you and the financial instruments get to see the details you upload.",
    "Our partnered NBFCs will check the credibility of the buyer along with the authenticity of the uploaded documents and respond within 24-48 hrs.",
    "NBFCs approve credit line for buyer and issue finance upto 90% against export invoice with no hidden charges involved",
    "The day buyer pays for the export invoice, the NBFCs remit the remaining 10% to the exporter",
  ],
  Para3:
    "The above process was the quickest and safest way for the management which helped them get the credit limits approved for their buyer in a timespan of mere 24-48 hours after generating theexport invoices. The moment M/s Rijul International got their buyer credit limits approved, they forwarded the proposal to the buyer and the buyer gave his acknowledgement to the proposed export factors over the digital channel. This came out as a win-win situation for both the M/s Rijul International and the buyer, as the buyer got their products without paying for an advance or issuing LC(letter of credit), and the M/s Rijul International also enjoyed a collateral free credit for theirexport invoices.",
  Para4:
    "The above process was so convenient for Galaxy International to improve their cash flow and their buyer also supported them for new finance arrangement so that they can fulfill new orders in pipeline. This wouldn't have been possible earlier for the M/s Galaxy International to close new orders on time due to lack of working capital. They were used to pay cash to their sources for maintaining cash flowfrom varied sources, as well as to procure material from new source. With the advent of TradeReboot platform they are now availingTrade Finance(Invoice Discounting/ Invoice Financing/ Export Financing)services from our partner lenders without any collateral, helping them in improving their cash flow and helping them unlock the stuck cash which was locked in transit time.",
  Para5:
    "M/s Galaxy International was able to manage and improve their supplychain with the help of Tradereboot finance digital marketplace. They have access to alternate source of funds directly from their offices, rather than depending on traditional collateral backed finance from banks. M/s Galaxy International was able to increase their sales by 2x because of the increased working capital by way of discounting/ financingthe invoices, and utilizing the funds for new orders in the pipeline. The TradeReboot Finance marketplace is a state of the art trade finance platform empowering the international trade with trust and technology.",
  Image: ReadymadeImg,
};
