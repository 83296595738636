export const NavbarData = {
  Discountdata: [
    {
      Url: "/export-invoice",
      Name: " Export Invoice Discounting",
    },
    {
      Url: "/supply-chain",
      Name: "Supply chain finance",
    },
    {
      Url: "/export-lc-discounting",
      Name: "Export LC Discounting",
    },
    // {
    //   Url: "/freight-finance",
    //   Name: "Freight Finance",
    // },
  ],
  ContactData: [
    {
      Url: "/about-us",
      Name: " About Us",
    },
    {
      Url: "/contact-us",
      Name: " Contact Us",
    },
    {
      Url: "/on-boarding",
      Name: "Onboarding",
    },
    {
      Url: "/blog",
      Name: "Blog",
    },
  ],
};
