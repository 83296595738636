import React, { Suspense, useState, useEffect } from "react";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Contactus from "../common-components/Contactus";
import Loader from "../common-components/Loader";
import ShortCut from "../common-components/ShortCut";
import Banner from "./components/Banner";
import { ProcessData, EMarketplaceData } from "./StaticData";

const SignUpProcess = React.lazy(() => import("./components/SignUpProcess"));
const AddBuyerDetails = React.lazy(() =>
  import("./components/AddBuyerDetails")
);
const UploadInvoice = React.lazy(() => import("./components/UploadInvoice"));
const EditProfile = React.lazy(() => import("./components/EditProfile"));
const EmarketCard = React.lazy(() =>
  import("../Home Component/components/EmarketCard")
);

const Onboarding = () => {
  useEffect(() => {
    document.title = "Trade Reboot Fin |  Trade Finance";
  }, []);

  window.scrollTo(0, 0);
  const [tab, setTab] = useState("Sign-Up/Login");
  const [index, setIndex] = useState(0);

  const nextFun = () => {
    let page = ProcessData[index + 1];
    setIndex(index + 1);
    setTab(page);
  };

  const backFun = () => {
    let page = ProcessData[index - 1];
    setIndex(index - 1);
    setTab(page);
  };

  const indexFun = (val, ind) => {
    console.log(val, index);
    setTab(val);
    setIndex(ind);
  };

  return (
    <>
      <Banner setTab={(val, ind) => indexFun(val, ind)} tab={tab} />
      {tab === "Sign-Up/Login" && (
        <Suspense fallback={<Loader />}>
          <SignUpProcess />{" "}
        </Suspense>
      )}
      {tab === "Add Buyer Details" && (
        <Suspense fallback={<Loader />}>
          <AddBuyerDetails />{" "}
        </Suspense>
      )}
      {tab === "Upload Invoice" && (
        <Suspense fallback={<Loader />}>
          <UploadInvoice />
        </Suspense>
      )}
      {tab === "Process & Edit Profile" && (
        <Suspense fallback={<Loader />}>
          <EditProfile />
        </Suspense>
      )}
      <div className="container text-end mb-4 d-flex justify-content-end gap-4">
        <button
          onClick={() => backFun()}
          className={`btn btn-outline-info px-5 rounded-3 ${
            index === 0 && "d-none"
          } `}
        >
          <MdArrowBackIos className="fs-5 " />
          <span className="fs-6 pt-1">Back</span>{" "}
        </button>
        <button
          onClick={() => nextFun()}
          className={`btn btn-primary px-5 rounded-3 ${
            index === ProcessData.length - 1 && "disabled"
          } `}
        >
          <span className="fs-6 pt-1">Next</span>{" "}
          <MdArrowForwardIos className="fs-5" />
        </button>
      </div>
      <Suspense fallback={<Loader />}>
        <EmarketCard
          EMarketplaceData={EMarketplaceData}
          Heading="E-KYC Process"
        />
      </Suspense>

      <Contactus />
      <ShortCut />
    </>
  );
};
export default Onboarding;
