import RegisterImg from "../assets/Export Invoice Discounting/register-img.jpeg";
import ShippingImg from "../assets/Export Invoice Discounting/buyercheck.jpeg";
import UploadInvoice from "../assets/Export Invoice Discounting/upload-invoice.jpg";
import Repayment from "../assets/Export Invoice Discounting/Repayment.jpg";

export const KeyPoints = [
  "Turn working capital into a competitive advantage to expand and grow rapidly in the international market.",
  "Export businesses- both small and large- are availing our credit facility to not just fulfill more buyer orders but also pay for  inventory, raw materials, employees or other short-term needs.",
  "Start your journey with Drip Capital today and get advance against your trade receivables at the click of a button.",
];

export const ProcessData = {
  ProcessNavData: [
    {
      ID: "Home",
      Heading: "Sign Up",
      Description:
        "Sign Up to Trade reboot Finance Platform by just entering IEC & GST No. Fill up the sign up form  within 30 secs you will be able to log in ",
      ID2: "home-pill",
    },
    {
      ID: "Home2",
      Heading: " Buyer Check",
      Description:
        "Upload your Buyer details my entering your buyer name and country & select buyer though DUNS No, Within 48 Hrs. you will get credibility of your buyer.",
      ID2: "home2-pill",
    },
    {
      ID: "Home3",
      Heading: "Apply for Finance",
      Description:
        "After Getting Credibility of your Buyer from Factors you can easily apply for  export finance by uploading scan copy of documents ",
      ID2: "home3-pill",
    },
    {
      ID: "Home4",
      Heading: "Invoice Discounting",
      Description:
        "Get 90 % of your export  Invoice Value  within 24 Hrs  after uploading post shipment documents.",
      ID2: "home4-pill",
    },
  ],
  ProcessImgData: [
    {
      ID: "Home",
      ID2: "home-pill",
      Image: RegisterImg,
    },
    {
      ID: "Home2",
      ID2: "home2-pill",
      Image: ShippingImg,
    },
    {
      ID: "Home3",
      ID2: "home3-pill",
      Image: UploadInvoice,
    },
    {
      ID: "Home4",
      ID2: "home4-pill",
      Image: Repayment,
    },
  ],
};



export const DiscountingProcessData =[
  {
    Number:"01",
    Heading:"Sign Up",
    Description:"Sign Up to Trade reboot Finance Platform by just entering IEC & GST No. Fill up the sign up form within 30 secs you will be able to log in"
  },
  {
    Number:"02",
    Heading:"Buyer Check",
    Description:"Upload your Buyer details my entering your buyer name and country & select buyer though DUNS No, Within 48 Hrs. you will get credibility of your buyer."
  },
  {
    Number:"03",
    Heading:"Apply for Finance",
    Description:"After Getting Credibility of your Buyer from Factors you can easily apply for export finance by uploading scan copy of documents."
  },
  {
    Number:"04",
    Heading:"Invoice Discounting",
    Description:"Get 90 % of your export Invoice Value within 24 Hrs after uploading post shipment documents."
  },
];