import React, { Suspense, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import ShortCut from "../common-components/ShortCut";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
// import { BlogData } from "./StaticData";
// import blog1 from "../assets/Blog/financial instiution.png";
import axios from "axios";
import parse from "rss-to-json";
import { tradeFinBackendUrl } from "../../config";
import Loader from "../common-components/Loader";

const BlogCard = React.lazy(() => import("./components/BlogCard"));
const LatestBlog = React.lazy(() => import("./components/LatestBlog"));

const Blog = () => {

  const [blogData, setBlogData] = useState({})

  useEffect(() => {
    axios.post(tradeFinBackendUrl + "/blogs/fetch-blogs").then((result) => {
      if (result.data && result.data.data) {
        setBlogData(result.data.data);
      }
    });
  }, [])


  return (
    <div className="bg-light">
      <Navbar />
      {blogData.items && blogData.items.length && (
        <Link
          to={`/blog/${blogData.items[0].blogEndPoint}`}
          target="_blank"
          className="text-decoration-none text-dark"
        >
          <Suspense fallback={<Loader />}><LatestBlog data={blogData.items[0]} /></Suspense>
        </Link>
      )}

      <div className="container">
        {" "}
        {blogData.items && blogData.items.length > 1 && (
          <Suspense fallback={<Loader />}><BlogCard data={blogData.items.slice(1)} /></Suspense>

        )}
        <Footer />
      </div>
      <ShortCut />
    </div>
  );
};
export default Blog;
