/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { RiLinkedinFill } from "react-icons/ri";
import { AiFillGoogleCircle } from "react-icons/ai";
import validate from "./SignUpValidation";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import {
  platformBackendUrl,
  platformUrl,
  tradeFinBackendUrl,
} from "../../config";
import axios from "axios";
import toastDisplay from "../ToastNotification";
import { ToastContainer } from "react-toastify";
import { encryptData } from "../../constants/myFunctions";

const SignUpModal = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const astrix = <span className="required-field text-danger">*</span>;

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleLogin();
    } else if (isSubmitting) {
      setIsSubmitting(false);
    }
  }, [error]);

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  console.log("dataa=> ", data);
  console.log("error=> ", error);

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };

  function handleLogin() {
    setShowLoader(true);
    axios
      .post(tradeFinBackendUrl + "/users/checkIfUserInquiryExist", {
        email: data.email,
      })
      .then((result) => {
        setShowLoader(false);
        // console.log("resulttt2", result);
        if (result.data.status) {
          toastDisplay(
            "Redirecting to tradereboot platform to complete registration process",
            "info"
          );
          setTimeout(() => {
            window.open(
              platformUrl +
                "/registration?tradefin_ref=" +
                encryptData(JSON.stringify({ username: data.email })),
              "_blank"
            );
          }, 3000);
        } else {
          // toastDisplay("Invalid Credentials", "error")
          axios
            .post(platformBackendUrl + "/login", {
              username: data.email,
              password: encryptData(data.password),
            })
            .then((result) => {
              // console.log("resulttt", result.data);
              if (result.data.success) {
                // Navigate to platform dashboard if credentials found
                toastDisplay(
                  "Logged in successfully, redirecting to tradereboot platform",
                  "success"
                );
                setTimeout(() => {
                  window.open(
                    platformUrl +
                      "/login?tradefin_ref=" +
                      encryptData(
                        JSON.stringify({
                          username: data.email,
                          password: data.password,
                        })
                      ),
                    "_blank"
                  );
                }, 3000);
              } else if (result.data.error) {
                toastDisplay(result.data.error, "error");
              } else {
                toastDisplay("Something went wrong", "error");
              }
            });
        }
      });
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <div className="modal-dialog modal-dialog-centered ">
        <div
          className="modal-content NavBar-section "
          style={{ borderRadius: "15px" }}
        >
          <div className="modal-header align-items-center border-0 ">
            <h5 className="modal-title" id="exampleModalToggleLabel">
              LogIn
            </h5>
            <p
              className=" cursor fs-4 mb-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <AiOutlineCloseCircle />
            </p>
          </div>
          <div className="modal-body border-top border-bottom-0">
            <div className="col-11 mx-auto">
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="name@example.com"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                />
                <label htmlFor="floatingInput">Email address {astrix}</label>
                {error.email === "Field Missing" ? (
                  <span className="text-danger fontValid"> Field Missing</span>
                ) : (
                  ""
                )}
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                />
                <label htmlFor="floatingPassword">Password {astrix}</label>
                {error.password === "Field Missing" ? (
                  <span className="text-danger fontValid"> Field Missing</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-10 mx-auto justify-content-center border-0 mb-4">
            <button
              className="btn btn-primary col-12 rounded-3"
              // data-bs-target="#exampleModalToggle2"
              // data-bs-toggle="modal"
              onClick={(e) => preHandleSubmit(e)}
            >
              Login
            </button>
            <div className="d-flex justify-content-between">
              {/* <div>
                <a
                  href="#"
                  className="text-primary small"
                  style={{ fontSize: "12px" }} hrefLang="en-us" rel="alternate"
                >
                  Forgot Password
                </a>
              </div> */}
              <div>
                <a
                  // href=""
                  // onClick={()=>setLogin(false)}
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  className="text-primary small"
                  style={{ fontSize: "12px" }} hrefLang="en-us" rel="alternate"
                >
                  New User Register
                </a>
              </div>
            </div>
            {/* <div className="mt-3 d-flex align-items-center gap-2">
              <p className="mb-0 me-2">Or Login Using:</p>
              <div className="d-flex align-items-center SocialMediaFooter">
                <a href="https://www.facebook.com/TradeReboot" className=" mb-0 cursor p-1 px-2" hrefLang="en-us" rel="alternate">
                  <FaFacebook style={{ marginTop: "-3px" }} />
                </a>
              </div>
              <div className="d-flex align-items-center SocialMediaFooter">
                <a href="#" className=" mb-0 cursor p-1 px-2" hrefLang="en-us" rel="alternate">
                  <FaInstagram style={{ marginTop: "-3px" }} />
                </a>
              </div>
              <div className="d-flex align-items-center SocialMediaFooter">
                <a href="https://www.linkedin.com/company/trade-reboot/" className=" mb-0 cursor p-1 px-2" hrefLang="en-us" rel="alternate">
                  <RiLinkedinFill style={{ marginTop: "-3px" }} />
                </a>
              </div>
              <div className="d-flex align-items-center SocialMediaFooter">
                <a href="#" className=" mb-0 cursor p-1 px-2" hrefLang="en-us" rel="alternate">
                  <AiFillGoogleCircle style={{ marginTop: "-3px" }} />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default SignUpModal;
