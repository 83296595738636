import React from "react";

const SelectField = ({ children, Label, AriaLabel, Id, Name, Value, OnChange, Astrix, ValidError }) => {
  return (
    <div className="form-floating mb-3">
      <select
        className="form-select FormSelect"
        id={Id}
        aria-label={AriaLabel}
        name={Name} 
        value={Value} 
        onChange={OnChange}
      >
        {children}
      </select>
      <label htmlFor="floatingSelect">
        {Label} {Astrix}
      </label>
      {ValidError === 'Field Missing' ? <span className="text-danger fontValid"> Field Missing</span> : ''}
    </div>
  );
};
export default SelectField;
