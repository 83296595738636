/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

const ShortCut = () => {
  return (
    <div className="scrolltop-wrap ">
      <a role="button" aria-label="Scroll to top" hrefLang="en-us" rel="alternate">
        <BsFillArrowUpCircleFill 
        onClick={()=>window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}
        className="fs-1 text-info" />
      </a>
    </div>
  );
};
export default ShortCut;
