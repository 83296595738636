/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaFacebook, FaInstagram } from "react-icons/fa";

import { RiLinkedinFill } from "react-icons/ri";
import { AiFillGoogleCircle } from "react-icons/ai";
import InputField from "../common-components/InputField";
import SelectField from "../common-components/SelectField";
import validate from "./RegisterValidation";
import {
  platformBackendUrl,
  platformUrl,
  tradeFinBackendUrl,
} from "../../config";
import axios from "axios";
import toastDisplay from "../ToastNotification";
import { ToastContainer } from "react-toastify";
import { encryptData } from "../../constants/myFunctions";

const industryData = [
  { value: "apparelTextile", name: "Apparels & Textile" },
  { value: "industrialMech", name: "Industrial & Mechanical" },
  { value: "foodBeverage", name: "Food & Beverage" },
  { value: "electronicConsumer", name: "Electronics & Consumer Goods" },
  { value: "eCommerce", name: "E-Commerce" },
  { value: "gamingMedia", name: "Gaming & Media" },
  { value: "fmcg", name: "FMCG" },
  { value: "medicalPharma", name: "Medical & Pharmaceutical" },
  { value: "auto", name: "Auto" },
  { value: "frozenFood", name: "Frozen Foods" },
  { value: "ITStaffing", name: "IT Staffing" },
  { value: "argo", name: "AGRO" },
  { value: "govtEntity", name: "Buyer/Supplier Government Entity" },
  { value: "oilGas", name: "Oil/Gas" },
  { value: "diamond", name: "Diamond" },
  { value: "preciousStone", name: "Precious Stone" },
  { value: "gold", name: "Gold" },
  { value: "others", name: "Others" },
];

const RegisterModal = () => {
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userId, setUserId] = useState(19);
  let [countryData, setcountryData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const astrix = <span className="required-field text-danger">*</span>;

  useEffect(() => {
    axios.get(platformBackendUrl + "/getallCountry").then((result) => {
      if (result.data.message && result.data.message.length) {
        setcountryData(result.data.message);
      }
    });
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleRegister();
    } else if (isSubmitting) {
      setIsSubmitting(false);
    }
  }, [error]);

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data, userId));
    setIsSubmitting(true);
  };

  function handleRegister() {
    // console.log("handleRegister==>", data);
    setShowLoader(true);
    axios
      .post(tradeFinBackendUrl + "/users/checkIfUserInquiryExist", {
        email: userId === 19 ? data.email : data.bank_email,
      })
      .then((result) => {
        setShowLoader(false);
        // console.log("result1", result);
        if (result.data.status) {
          toastDisplay(
            "Redirecting to tradereboot platform to complete registration process",
            "info"
          );
          setTimeout(() => {
            window.open(
              platformUrl +
                "/registration?tradefin_ref=" +
                encryptData(
                  JSON.stringify({
                    username: userId === 19 ? data.email : data.bank_email,
                  })
                ),
              "_blank"
            );
          }, 3000);
        } else {
          let req = {};
          if (userId === 19) {
            req = {
              ...data,
              user_type_id: userId,
              contact: data.phone,
              password: encryptData(data.password),
            };
          } else if (userId === 8) {
            req["email"] = data["bank_email"];
            req["contact"] = data["bank_phone"];
            req["name"] = data["bank_name"];
            req["company_name"] = data["bank_company_name"];
            req["gst"] = null;
            req["iec"] = null;
            req["password"] = encryptData(data["bank_password"]);
            req["country"] = data["bank_country"];
            req["industry_type"] = data["bank_industry_type"];
            req["user_type_id"] = userId;
          }
          setShowLoader(true);
          axios
            .post(
              tradeFinBackendUrl + "/users/insertUserRegistrationInquiry",
              req
            )
            .then((result) => {
              setShowLoader(false);
              // console.log("insertUserRegistrationInquiry", result);
              if (result.data.status) {
                toastDisplay(
                  "Redirecting to tradereboot platform to complete registration process",
                  "info"
                );
                setTimeout(() => {
                  window.open(
                    platformUrl +
                      "/registration?tradefin_ref=" +
                      encryptData(JSON.stringify({ username: req["email"] })),
                    "_blank"
                  );
                }, 3000);
              } else {
                toastDisplay("Something went wrong", "error");
              }
            });
        }
      });
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div
          className="modal-content NavBar-section "
          style={{ borderRadius: "15px" }}
        >
          <div className="modal-header align-items-center border-0 ">
            <h5 className="modal-title" id="exampleModalToggleLabel2">
              Sign Up{" "}
            </h5>
            <ul className="nav nav-pills gap-4" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="btn btn-primary active px-5 py-2"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  onClick={() => setUserId(19)}
                >
                  Expoters/Importers
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="btn btn-primary px-5 py-2 "
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                  onClick={() => setUserId(8)}
                >
                  Bankers/NBFC's
                </button>
              </li>
            </ul>
            <p
              className=" cursor fs-4 mb-0"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <AiOutlineCloseCircle />
            </p>
          </div>
          <div className="modal-body border-top border-bottom-0">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="row mx-4">
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Company Name"
                      InputType="text"
                      Placeholder="Company Name"
                      Id="company_name"
                      Name="company_name"
                      Value={data.company_name}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.company_name}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Name"
                      InputType="text"
                      Placeholder="Name"
                      Id="name"
                      Name="name"
                      Value={data.name}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.name}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Phone"
                      InputType="tel"
                      Placeholder="Phone"
                      Id="phone"
                      Name="phone"
                      Value={data.phone}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.phone}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Email"
                      InputType="email"
                      Placeholder="Email"
                      Id="email"
                      Name="email"
                      Value={data.email}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.email}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="GST Number "
                      InputType="text"
                      Placeholder="GST Number"
                      Id="gst"
                      Name="gst"
                      Value={data.gst}
                      OnChange={handleChange}
                      Astrix={""}
                      ValidError={""}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="IEC NUMBER  "
                      InputType="text"
                      Placeholder="IEC NUMBER "
                      Id="iec"
                      Name="iec"
                      Value={data.iec}
                      OnChange={handleChange}
                      Astrix={""}
                      ValidError={""}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Password"
                      InputType="password"
                      Placeholder="Password"
                      Id="password"
                      Name="password"
                      Value={data.password}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.password}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Re-Password"
                      InputType="password"
                      Placeholder="Re-Password"
                      Id="re_password"
                      Name="re_password"
                      Value={data.re_password}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.re_password}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <SelectField
                      Label="Country "
                      AriaLabel="country"
                      Id="country"
                      Name="country"
                      Value={data.country}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.country}
                    >
                      <option value="">Select Country</option>
                      {countryData.length
                        ? countryData.map((item) => (
                            <option value={item.sortname} key={item}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </SelectField>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <SelectField
                      Label="Industry Type "
                      AriaLabel="industry type"
                      Id="industry_type"
                      Name="industry_type"
                      Value={data.industry_type}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.industry_type}
                    >
                      <option value="">Select Industry Type</option>
                      {industryData.length
                        ? industryData.map((item) => (
                            <option value={item.value} key={item}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </SelectField>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <div className="row mx-4">
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Company Name"
                      InputType="text"
                      Placeholder="Company Name"
                      Id="bank_company_name"
                      Name="bank_company_name"
                      Value={data.bank_company_name}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.bank_company_name}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Name"
                      InputType="text"
                      Placeholder="Name"
                      Id="bank_name"
                      Name="bank_name"
                      Value={data.bank_name}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.bank_name}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Phone"
                      InputType="tel"
                      Placeholder="Phone"
                      Id="bank_phone"
                      Name="bank_phone"
                      Value={data.bank_phone}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.bank_phone}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Email"
                      InputType="email"
                      Placeholder="Email"
                      Id="bank_email"
                      Name="bank_email"
                      Value={data.bank_email}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.bank_email}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Password"
                      InputType="password"
                      Placeholder="Password"
                      Id="bank_password"
                      Name="bank_password"
                      Value={data.bank_password}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.bank_password}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <InputField
                      Label="Re-Password"
                      InputType="password"
                      Placeholder="Re-Password"
                      Id="bank_re_password"
                      Name="bank_re_password"
                      Value={data.bank_re_password}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.bank_re_password}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <SelectField
                      Label="Country "
                      AriaLabel="country"
                      Id="bank_country"
                      Name="bank_country"
                      Value={data.bank_country}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.bank_country}
                    >
                      <option value="">Select Country</option>
                      {countryData.length
                        ? countryData.map((item) => (
                            <option value={item.sortname} key={item}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </SelectField>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-8 col-sm-10 mx-auto">
                    <SelectField
                      Label="Industry Type "
                      AriaLabel="industry type"
                      Id="bank_industry_type"
                      Name="bank_industry_type"
                      Value={data.bank_industry_type}
                      OnChange={handleChange}
                      Astrix={astrix}
                      ValidError={error.bank_industry_type}
                    >
                      <option value="">Select Industry Type</option>
                      {industryData.length
                        ? industryData.map((item) => (
                            <option value={item.value} key={item}>
                              {item.name}
                            </option>
                          ))
                        : null}
                    </SelectField>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-11 mx-auto justify-content-center border-0 mb-4">
            <div className="text-center">
              <button
                className="btn col-6  btn-primary  rounded-3"
                // data-bs-target="#exampleModalToggle2"
                // data-bs-toggle="modal"
                onClick={(e) => preHandleSubmit(e)}
              >
                Register
              </button>
            </div>
            <div className="d-flex col-6 justify-content-between mx-auto">
              {/* <div>
                <a
                  // href="/"
                  className="text-primary small"
                  style={{ fontSize: "12px" }}
                >
                  Forgot Password
                </a>
              </div> */}
              <div>
                <a
                  // href="/"
                  data-bs-target="#exampleModalToggle"
                  data-bs-toggle="modal"
                  className="text-primary small"
                  style={{ fontSize: "12px" }}
                >
                  Already registered
                </a>
              </div>
            </div>
            {/* <div className="mt-3 d-flex align-items-center gap-2 justify-content-center">
              <p className="mb-0 me-2">Or Login Using:</p>
              <div className="d-flex align-items-center SocialMediaFooter">
                <a href="https://www.facebook.com/TradeReboot" className=" mb-0 cursor p-1 px-2" hrefLang="en-us" rel="alternate">
                  <FaFacebook style={{ marginTop: "-3px" }} />
                </a>
              </div>
              <div className="d-flex align-items-center SocialMediaFooter">
                <a href="#" className=" mb-0 cursor p-1 px-2" hrefLang="en-us" rel="alternate">
                  <FaInstagram style={{ marginTop: "-3px" }} />
                </a>
              </div>
              <div className="d-flex align-items-center SocialMediaFooter">
                <a href="https://www.linkedin.com/company/trade-reboot/" className=" mb-0 cursor p-1 px-2" hrefLang="en-us" rel="alternate">
                  <RiLinkedinFill style={{ marginTop: "-3px" }} />
                </a>
              </div>
              <div className="d-flex align-items-center SocialMediaFooter">
                <a href="#" className=" mb-0 cursor p-1 px-2" hrefLang="en-us" rel="alternate">
                  <AiFillGoogleCircle style={{ marginTop: "-3px" }} />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default RegisterModal;
