/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Pulse from 'react-reveal/Pulse';

const EmarketCard = ({ EMarketplaceData, Heading, SubHeading }) => {
  return (
    <div className="container my-5">
      <div className="text-center">
        <h2 className="mb-0">{Heading}</h2>
        <p className="fw-bold" style={{ color: "rgb(101, 228, 253)" }}>
          {SubHeading}
        </p>
      </div>
      <div className="row ">
        {EMarketplaceData.map((key, index) => {
          return (
            <Pulse>

           
            <div
              className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mt-5"
              key={index}
            >
              <div className="mx-auto card border ecard ">
                <div className=" text-center">
                  <div className="pt-4">
                    <img
                      src={key.Image}
                      alt="ecard"
                      className="img-fluid rounded-3 pb-3"
                      width="140px"
                      height="140px"
                    />

                    <h6 className="card-title mt-2"> {key.heading} </h6>
                  </div>
                  <div className={key.classnames}>
                    <p>{key.Paragraph}</p>
                  </div>
                  <div className="card-footer pb-3 border-0 bg-transparent">
                    <a
                      href={key.Link}
                      className="text-decoration-none text-info"
                    >
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            </Pulse>
          );
        })}
      </div>
    </div>
  );
};
export default EmarketCard;
