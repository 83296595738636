import React from "react";
import { Outlet } from "react-router";
import Footer from "./Footer Component/Footer";
import NavbarMain from "./NavBar Component/NavbarMain";

const UserLayout = () => {
  return (
    <>
      <NavbarMain />
      <Outlet />
      <Footer />
    </>
  );
};
export default UserLayout;
