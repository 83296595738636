import React from "react";
import ImproveImage from "../../assets/Banner/ImproveLiquidity.png";
import Fade from 'react-reveal/Fade';

const ImproveLiquidity = ({ MarkerPointData }) => {
  return (
    <>
      <div className="container-fluid bg-light">
        <div className="container py-5">
          <div className="row">
            <Fade left>
              <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 mx-auto ImproveLiq">
                <img
                  src={ImproveImage}
                  alt="Improve"
                  height="450px"
                  className="rounded-3 img-fluid mb-3"
                />
              </div>
            </Fade>
            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 m-auto">
              <Fade top>
                <h3 className="mb-3 me-5 text-capitalize ">
                  can help to improve
                  <span style={{ color: "rgb(101, 228, 253)" }}>
                    {" "}
                    Liquidity and working capital
                  </span>{" "}
                </h3>
              </Fade>

              <ul className="MarkerPoint">
                {MarkerPointData.map((key, index) => {
                  return (
                    <li key={index} className="mt-2">
                      {key}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ImproveLiquidity;
