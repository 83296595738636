export default function validate(values) {

    let errors = {};
  
    if (!values.name) {
      errors.name = 'Field Missing'
    }
    if (!values.gmail) {
      errors.gmail = 'Field Missing';
    }
    if (!values.phone) {
      errors.phone = 'Field Missing';
    }
    if (!values.organization_name) {
      errors.organization_name = 'Field Missing';
    }
    if(!values.country){
      errors.country="Field Missing";
    }
    if (!values.organization_type) {
        errors.organization_type = "Field Missing";
    }
    if (!values.comments) {
      errors.comments = 'Field Missing';
    }
    console.log(errors);
    return errors;
}