import React from "react";
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { FaLinkedin } from "react-icons/fa";

const Carousel = ({ Image, Name, Role, Description }) => {
  return (
    <div className="col-xl-10 col-lg-11 col-md-11 col-sm-10 mx-auto TeamCard border my-5">
      <div className="row ">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 m-auto text-center py-5 px-3  bg-light TeamLeft">
          <img src={Image} alt="carosuel" className="text-center" />
          <div className="text-center mt-3">
            <button
              className="carousel-prev btn "
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <BsFillArrowLeftCircleFill className="text-info carousel-prev-icon fs-3" />
            </button>
            <button
              className="carousel-next btn"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <BsFillArrowRightCircleFill className="text-info carousel-control-icon fs-3" />
            </button>
          </div>
        </div>
        <div className="col-xl-8 col-lg-10 col-md-11 col-sm-11 m-auto  py-5 px-5 ">
          <div className="d-flex align-items-center gap-1">
            <h3 className="">{Name}</h3>
            <FaLinkedin href="https://www.linkedin.com/company/trade-reboot/" className="fs-4 CustomColor IconAnimation" />
          </div>

          <h6>{Role}</h6>
          <p>{Description}</p>
          <button className="btn btn-primary rounded-3">Read More</button>
        </div>
      </div>
    </div>
  );
};
export default Carousel;
