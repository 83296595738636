import RegisterImg from "../assets/Export Invoice Discounting/register-img.jpeg";
import ShippingImg from "../assets/Export Invoice Discounting/buyercheck.jpeg";
import UploadInvoice from "../assets/Export Invoice Discounting/upload-invoice.jpg";
import Repayment from "../assets/Export Invoice Discounting/Repayment.jpg";

export const KeyPoints = [
  "Supply chain finance  is an extension of the buyer’s accounts payable and is not considered financial debt. For the supplier, it represents a true sale of their receivables..",
  "Using supply chain factoring assures that the suppliers will still be in business, and are reliable.",
  " With the supply chain financing, instead of paying numerous suppliers, most of the invoices are centralized with the same factor",
];

export const ProcessData = {
  ProcessNavData: [
    {
      ID: "Home",
      Heading: "Sign Up",
      Description:
        " Sign Up to Tradereboot Finance Platform by just entering IEC & GST No. Fill up the sign up form  within 30 secs you will be able to log in.",
      ID2: "home-pill",
    },
    {
      ID: "Home2",
      Heading: "  Supplier Uploads Invoice ",
      Description:
        "Supplier uploads an invoice to the supply chain platform, with payment due on a future date.",
      ID2: "home2-pill",
    },
    {
      ID: "Home3",
      Heading: "Supplier Receives Payment",
      Description:
        "After Supplier uploads Invoice, Buyer will approve the invoice  after reviewing documents supplier gets 90% of  export Invoice value from Factor.",
      ID2: "home3-pill",
    },
    {
      ID: "Home4",
      Heading: "Maturity Date",
      Description:
        "Buyer sends payment to the factor on the due date and factor remits remaining 10% minus fees  to Supplier.",
      ID2: "home4-pill",
    },
  ],
  ProcessImgData: [
    {
      ID: "Home",
      ID2: "home-pill",
      Image: RegisterImg,
    },
    {
      ID: "Home2",
      ID2: "home2-pill",
      Image: ShippingImg,
    },
    {
      ID: "Home3",
      ID2: "home3-pill",
      Image: UploadInvoice,
    },
    {
      ID: "Home4",
      ID2: "home4-pill",
      Image: Repayment,
    },
  ],
};
