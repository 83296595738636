import React from "react";
import { NavLink } from "react-router-dom";
import Page404 from "../assets/404-page.svg"

const PageError = () => {
    return (

        <div className="p-5 text-center mt-5">
            <img src={Page404} alt="" height={300} width={300} />
            <h3>404 Error</h3>
            <p>We can't find the page you're looking for.</p>
            <NavLink to="/"><button className="btn btn-primary px-4">Back to Home</button></NavLink>
        </div>

    );
}
export default PageError;