import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import ExportInvoice from "../common-components/ExportingInvoice";
import ShortCut from "../common-components/ShortCut";
import Banner from "./components/Banner";
import { KeyPoints } from "./StaticData";
import { DiscountingProcessData } from "../ExportInvoice Discounting/StaticData";
import { Fade } from "react-reveal";
import Loader from "../common-components/Loader";

const Benifits = React.lazy(() => import("../common-components/Benifits"));
const DiscountingProcess = React.lazy(() => import("../common-components/DiscountingProcess"));

const SupplyChain = () => {
  return (
    <>
      <Helmet>
        <title>Supply Chain Finance - Process & Benefits | Trade Reboot Fin</title>
        <meta
          name="description"
          content="Supply chain finance, also known as import or supplier finance, There are 3 simple steps in supply chain factoring process.Signup, upload Invoice & receive payment."
        />
      </Helmet>

      <Banner />
      <ExportInvoice KeyPoints={KeyPoints}>
        <Fade top>
          <h3 className="mb-4">
            Supply{" "}
            <span style={{ color: "rgb(101, 228, 253)" }}>Chain Finance</span>{" "}
          </h3></Fade>
      </ExportInvoice>
      <div className="my-5">
        <Fade top>
          <h3 className="mb-5 text-center">
            Export{" "}
            <span style={{ color: "rgb(101, 228, 253)" }}>
              LC Discounting
            </span>{" "}
            Process
          </h3>
        </Fade>
        <Suspense fallback={<Loader />}>
          <DiscountingProcess DiscountingProcessData={DiscountingProcessData} />
        </Suspense>
      </div>
      <Suspense fallback={<Loader />}>
        <Benifits>
          <Fade top>
            <h3 className="text-center mb-4">
              Supply Chain{" "}
              <span style={{ color: "rgb(101, 228, 253)" }}>Benefits </span>
            </h3></Fade>
        </Benifits>
      </Suspense>

      <ShortCut />
    </>
  );
};
export default SupplyChain;
