/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import InputField from "../common-components/InputField";
import SelectField from "../common-components/SelectField";
import contactImg from "../assets/Contact-us/contact-Page.svg";
import Logo from "../assets/logo_1.png";
import validate from "./ContactValidation";
import toastDisplay from "../ToastNotification";
import ShortCut from "../common-components/ShortCut";
import FQA from "./FAQ";
import { FQAData } from "./StaticData";
import { Helmet } from "react-helmet";
import HeadShake from "react-reveal/HeadShake";
import Flash from "react-reveal/Flash";
import { Fade, Slide } from "react-reveal";
import { tradeFinBackendUrl } from "../../config";
import { ToastContainer } from "react-toastify";

const organizationType = [
  { id: 1, name: "NBFCs" },
  { id: 2, name: "Exporter" },
  { id: 3, name: "Importer" },
];

const ContactUs = () => {
  useEffect(() => {
    document.title =
      "Contact Us for Trade & Export Financing Services | Trade Reboot Fin";
  }, []);

  window.scrollTo(0, 0);
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [refresh, setrefresh] = useState(0);
  const [country, setCountry] = useState([]);

  const astrix = <span className="required-field text-danger">*</span>;

  useEffect(() => {
    fetch("https://webfindev-backend.sofodev.co/getCountry", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setCountry(data);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(error).length === 0 && isSubmitting) {
      handleSubmit();
    } else if (isSubmitting) {
      setIsSubmitting(false);
    }
  }, [error]);

  const handleChange = (event) => {
    event.persist();
    setData({ ...data, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: "" });
  };

  const preHandleSubmit = (event) => {
    if (event) event.preventDefault();
    setError(validate(data));
    setIsSubmitting(true);
  };

  function handleSubmit() {
    setShowLoader(true);

    data["organization_type_name"] =
      organizationType[data.organization_type - 1]["name"];

    fetch(`${tradeFinBackendUrl}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((result) => {
        setShowLoader(false);
        toastDisplay("Query received successfully", "success");
        setTimeout(() => {
           window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        setShowLoader(false);
        toastDisplay("Something went wrong", "error");
      });
  }

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      {showLoader && (
        <div className="loading-overlay">
          <span>
            <img
              className=""
              src="assets/images/loader.gif"
              alt="description"
            />
          </span>
        </div>
      )}
      <Helmet>
        <title>
          Contact Us for Trade & Export Financing Services | Trade Reboot Fin
        </title>
        <meta
          name="description"
          content="Trade Reboot fin provides digital trade platform for trade & export financing services."
        />
      </Helmet>
      <div className="container my-5">
        <div className="text-center">
          <HeadShake>
            <h1>
              <span className="borderStart  me-2 borderinfo border-5 rounded-3"></span>
              Contact Us
            </h1>
          </HeadShake>
          <p className="mt-3 fs-5">
            Looking for a{" "}
            <span style={{ color: "rgb(101, 228, 253)" }}>Collaboration?</span>
          </p>
        </div>
        <div className="container col-xl-9 col-lg-9 col-md-11 col-sm-12 mx-auto my-5">
          <div className="row gap-3 justify-content-between ">
            <div className="col-xl-3 col-lg-3 col-md-7 col-sm-5 CustomBgColor rounded-3">
              <div className=" ">
                <img src={Logo} alt="Logo" className="img-fluid " />
                <p className="text-center text-white fs-5">
                  Spend Your 2 Mins Here 🙂
                </p>
              </div>
            </div>
            <Slide left>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3  mt-auto ContactUsImg ">
                <img
                  src={contactImg}
                  alt="contactImg"
                  className=""
                  height="545px"
                />
              </div>
            </Slide>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 my-auto">
              <InputField
                Label="Name"
                InputType="text"
                Placeholder="Name"
                Id="name"
                Name="name"
                Value={data.name}
                OnChange={handleChange}
                Astrix={astrix}
                ValidError={error.name}
              />
              <InputField
                Label="Email"
                InputType="email"
                Placeholder="Gmail"
                Id="gmail"
                Name="gmail"
                Value={data.gmail}
                OnChange={handleChange}
                Astrix={astrix}
                ValidError={error.gmail}
              />
              <InputField
                Label="Phone"
                InputType="tel"
                Placeholder="Phone"
                Id="phone"
                Name="phone"
                Value={data.phone}
                OnChange={handleChange}
                Astrix={astrix}
                ValidError={error.phone}
              />
              <InputField
                Label="Organization Name"
                InputType="text"
                Placeholder="Organization Name"
                Id="organization_name"
                Name="organization_name"
                Value={data.organization_name}
                OnChange={handleChange}
                Astrix={astrix}
                ValidError={error.organization_name}
              />
              <SelectField
                Label="Country "
                AriaLabel="country"
                Id="country"
                Name="country"
                Value={data.country}
                OnChange={handleChange}
                Astrix={astrix}
                ValidError={error.country}
              >
                <option value="">--Select Country--</option>
                {country.map((key, value) => {
                  return (
                    <option defaultValue={key.sortname}>{key.name}</option>
                  );
                })}
              </SelectField>
              <SelectField
                Label="Organization Type "
                AriaLabel="Organization Type"
                Id="organization_type"
                Name="organization_type"
                Value={data.organization_type}
                OnChange={handleChange}
                Astrix={astrix}
                ValidError={error.organization_type}
              >
                <option value="" selected>
                  --Select Organization Type--
                </option>
                {organizationType.map((item) => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </SelectField>
              <div className="form-floating ">
                <textarea
                  className="form-control FormControl"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  name="comments"
                  value={data.comments}
                  onChange={handleChange}
                ></textarea>
                <label htmlFor="floatingTextarea2">Comments {astrix}</label>
                {error.comments === "Field Missing" ? (
                  <span className="text-danger fontValid"> Field Missing</span>
                ) : (
                  ""
                )}
              </div>
              <button
                className="btn btn-primary rounded-3 mt-3"
                onClick={(e) => preHandleSubmit(e)}
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto Location my-5">
            <h3 className="text-center my-5 ">
              Our <span style={{ color: "rgb(101, 228, 253)" }}>Location</span>
            </h3>
            <iframe
              className="col-12 mx-auto"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60303.55045188583!2d72.84367842707029!3d19.15270713313371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b65a7edbbdb9%3A0xf447c38599143078!2sGoregaon%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1635946649831!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              loading="lazy"
            ></iframe>
          </div>
          {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto Location my-5">
            {" "}
            <Fade top>
              <h3 className="text-center mt-5 ">
                Our <span style={{ color: "rgb(101, 228, 253)" }}>FAQ </span>
              </h3>
            </Fade>
            <FQA FQAData={FQAData} />
          </div> */}
        </div>

        <ShortCut />
      </div>
    </>
  );
};
export default ContactUs;
