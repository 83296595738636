import React, { Suspense, useEffect, useState } from "react";
import Contactus from "../common-components/Contactus";
import Loader from "../common-components/Loader";
import ShortCut from "../common-components/ShortCut";
import Banner from "./components/Banner";
import Benefits from "./components/Benefits";
import { ClientsData } from "./StaticData";
import { Helmet } from "react-helmet";

const AboutUs = React.lazy(() => import("./components/AboutUs"));
const OurClients = React.lazy(() => import("./components/OurClients"));
const OurVision = React.lazy(() => import("./components/OurVision"));
const IndustriesWeServe = React.lazy(() =>
  import("./components/IndustriesWeServe")
);

const Aboutus = () => {
  useEffect(() => {
    document.title =
      "Collateral Free Export & Trade Finance Marketplace | Trade Reboot Fin";
  }, []);

  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <title>
          Collateral Free Export & Trade Finance Marketplace | Trade Reboot Fin
        </title>
        <meta
          name="description"
          content="Trade Reboot fin is collateral free export invoicing & trade Finance Marketplace. We help SME's and MSME's sector in international trade."
        />
      </Helmet>
      <Banner />
      <Suspense fallback={<Loader />}>
        <AboutUs />
        <OurVision />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <OurClients ClientsData={ClientsData} />
        <IndustriesWeServe />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Benefits />
      </Suspense>
      <Contactus />
      <ShortCut />
    </>
  );
};
export default Aboutus;
