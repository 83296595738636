export default function validate(values, userId) {

    let errors = {};
    let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    if (userId === 8) {
        if (!values.bank_company_name) {
            errors.bank_company_name = 'Field Missing'
        }
        if (!values.bank_name) {
            errors.bank_name = 'Field Missing';
        }
        // if (!values.bank_phone) {
        //     errors.bank_phone = 'Field Missing';
        // }
        if (!values.bank_phone) {
            errors.bank_phone = 'Field Missing';
        } else if (isNaN(values.bank_phone)) {
            errors.bank_phone = 'Phone No Should be a number';
        } else if (values.bank_phone.length < 10) {
            errors.bank_phone = 'Phone No should be of atleast 10 digits';
        }
        if (!values.bank_email) {
            errors.bank_email = 'Field Missing';
        }
        if (!values.bank_password) {
            errors.bank_password = 'Field Missing';
        }
        else if (!strongRegex.test(values.bank_password)) {
            errors.bank_password = 'Password Does not meet the Policy. It requires min 8 Character, 1 Uppercase and Lowercase letter, 1 Number & 1 Symbol';
        }
        if (!values.bank_re_password) {
            errors.bank_re_password = 'Field Missing';
        }
        else if (values.bank_password !== values.bank_re_password) {
            errors.bank_re_password = "Those Passwords didn't match. Try again";
        }
        if (!values.bank_country) {
            errors.bank_country = 'Field Missing';
        }
        if (!values.bank_industry_type) {
            errors.bank_industry_type = 'Field Missing';
        }
    }

    else {
        if (!values.company_name) {
          errors.company_name = 'Field Missing'
        }
        if (!values.name) {
          errors.name = 'Field Missing';
        }
        // if (!values.phone) {
        //     errors.phone = 'Field Missing';
        // }
        if (!values.phone) {
            errors.phone = 'Field Missing';
        } else if (isNaN(values.phone)) {
            errors.phone = 'Phone No Should be a number';
        } else if (values.phone.length < 10) {
            errors.phone = 'Phone No should be of atleast 10 digits';
        }
        if (!values.email) {
            errors.email = 'Field Missing';
        }
        if (!values.password) {
            errors.password = 'Field Missing';
        }
        else if (!strongRegex.test(values.password)) {
            errors.password = 'Password Does not meet the Policy. It requires min 8 Character, 1 Uppercase and Lowercase letter, 1 Number & 1 Symbol';
        }
        if (!values.re_password) {
            errors.re_password = 'Field Missing';
        }
        else if (values.password !== values.re_password) {
            errors.re_password = "Those Passwords didn't match. Try again";
        }
        if (!values.country) {
            errors.country = 'Field Missing';
        }
        if (!values.industry_type) {
            errors.industry_type = 'Field Missing';
        }
    }
    console.log(errors);
    return errors;
}